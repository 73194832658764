<div class="select_trigger_cont">
	<button (mouseenter)="customerShow = true"
			(click)="customerShow = !customerShow"
			class="sf-indicator transition flex items-center justify-center gap-1">
		<span *ngIf="!customerShow" class="material-symbols-outlined">
					person
		</span>
		<span *ngIf="customerShow" class="material-symbols-outlined">
					close
		</span>
		<span class="text-xs mr-2">{{tenantName}}</span>
		<span *ngIf="hasActiveOrder" class="status-dot status-red mt-[1px]"></span>
	</button>

	<div class="select" [@enterAnimation] *ngIf="customerShow" (mouseleave)="customerShow = false">
		<a routerLink="/profile" class="option " (click)="trackNavigationClick('Dashboard')">
			Dashboard
		</a>

		<a routerLink="/profile/order" [queryParams]="{'tab' : hasActiveOrder? 'active': 'all'}"
		   class="option sf-indicator flex items-center justify-between gap-1" (click)="trackNavigationClick(hasActiveOrder ? 'Active Order': 'Orders')">
			<p class="mr-1.5">{{ hasActiveOrder ? 'Active Order': 'Orders' }}</p>
			<span *ngIf="hasActiveOrder" class="status-dot status-red"></span>
		</a>
		<a routerLink="/profile/account" class="option " (click)="trackNavigationClick('Account')">
			Account
		</a>
		<a routerLink="/contact" (click)="trackNavigationClick('Contact')" class="option">
			Contact Us
		</a>
		<div (click)="logout()" class="option flex gap-2 items-center">
			Logout
			<span class="material-symbols-outlined">
					logout
			</span>
		</div>


	</div>
</div>
