import {Injectable} from '@angular/core';
import {Cart} from '../interface/cart';
import {GetCart, GetCartSelectedFinishProfileItemList} from '../interface/cart-get';
import {CartCalculationService} from './cart-calculation.service';
import {MadeToOrderFabric} from 'src/app/product-details/interface/made-to-order-fabric';
import {CheckForDuplicateCartDataService} from './check-for-duplicate-cart-data.service';
import {MadeToOrderProfile} from 'src/app/product-details/interface/made-to-order-profile';
import {CustomSizeProfile} from "../../product-details/interface/custom-size-profile";

@Injectable({
	providedIn: 'root'
})
export class PrepareGetCartService {

	constructor(
		private _cartCalculation: CartCalculationService,
		private _checkForDuplicateCartDataService: CheckForDuplicateCartDataService
	) { }

	public prepareCartList(itemList: GetCart[]): Cart[] {
		// debugger
		let renderList: Cart[] = [];
		let renderItem: Cart = {} as Cart;


		itemList.forEach((elm: GetCart) => {
			renderItem = {} as Cart;
			renderItem.finishedProductId = elm.finishedProductPreview ? elm.finishedProductPreview!.id : 0;
			renderItem.fabricProductId = elm.fabricProductPreview ? elm.fabricProductPreview!.id : 0;


			// renderItem.selectedSizeProfileOptionId = elm.selectedSizeOption ? elm.selectedSizeOption!.id : 0;
			renderItem.selectedSizeOption = elm.selectedSizeOption?elm.selectedSizeOption:undefined;
			renderItem.selectedSizeOptionId = elm.selectedSizeOption ? elm.selectedSizeOption!.id : 0;

			// renderItem.selectedFabricProfileItemId = elm.selectedFabric ? elm.selectedFabric!.product.id : 0;
			renderItem.selectedFabricId = elm.selectedFabric ? elm.selectedFabric!.product.id : 0;
			renderItem.fabricName = elm.selectedFabric ? elm.selectedFabric!.product.name : '';

			// renderItem.selectedFinishProfileItemId = this._prepareSelectedFinisedId(elm.selectedFinishList);
			renderItem.selectedFinishId = this._prepareSelectedFinisedId(elm.selectedFinishList);

			if(elm.customSize && Object.keys(elm.customSize).length) {
				renderItem.customSize = elm.customSize;
			}


			renderItem.selectedSizeOption = elm.selectedSizeOption;
			renderItem.productGroup = elm.productGroup;
			renderItem.orderType = elm.orderType;
			renderItem.quantity = elm.quantity;
			renderItem.unit = elm.unit;
			renderItem.id = elm.id;
			renderItem.price = this._getPrice(elm);
			renderItem.image =
				elm.finishedProductPreview ? elm.finishedProductPreview.product.heroImage : elm.fabricProductPreview!.product.heroImage;

			renderItem.finishDisplayName = elm.finishDisplayName?elm.finishDisplayName:'Finish';
			renderItem.sizeDisplayName = elm.sizeDisplayName?elm.sizeDisplayName:'Size';

			if(renderItem.customSize) {
				renderItem.sizeDisplayName = elm.sizeDisplayName || (renderItem.customSize.hasOwnProperty('Pantone Shade')? 'Custom Pantone Dye': 'Size');
			}
			// debugger
			if (elm.finishedProductPreview) {
				renderItem.productName = elm.finishedProductPreview.product.name;
				renderItem.slug = elm.finishedProductPreview.product.slug;
				renderItem.sku = elm.finishedProductPreview.product.sku;
			}
			else {
				renderItem.productName = elm.fabricProductPreview!.product.name;
				renderItem.slug = elm.fabricProductPreview!.product.slug;
				renderItem.sku = elm.fabricProductPreview!.product.sku;
			}
			if(elm.orderType === 'MADE_TO_ORDER'){
				renderItem.minQuantity = elm.finishedProductPreview ?
				(elm.finishedProductPreview.product.madeToOrderProfileEnabled && elm.finishedProductPreview.product.madeToOrderProfile)?
					elm.finishedProductPreview.product.madeToOrderProfile.minimumOrderQuantity: 1
					:
				(elm.fabricProductPreview?.product.madeToOrderProfileEnabled && elm.fabricProductPreview!.product.madeToOrderProfile)? elm.fabricProductPreview.product.madeToOrderProfile.minimumOrderQuantity: 1;
			}

			renderItem.makingCharge = elm.makingCharge?elm.makingCharge:0;

			renderList.push(renderItem);

		});
		// debugger
		// console.log(renderList)
		this._checkForDuplicateCartDataService.storedCartItems = renderList;
		return renderList
	}


	private _prepareSelectedFinisedId(data: GetCartSelectedFinishProfileItemList[]): string {
		// debugger
		// console.log(data);
		if (!data[0]) {
			return ''
		}
		let id: string[] = [];
		data.forEach((elm: GetCartSelectedFinishProfileItemList) => {
			id.push(elm.id + '');
		})
		return id.join(",")
	}

	private _getPrice(data: GetCart): number {

		this._cartCalculation.inStockFinishPrice = 0
		// console.log(data);
		this._cartCalculation.selectedFabricItem = data.selectedFabric ? data.selectedFabric.product : {} as MadeToOrderFabric;
		// debugger

		let madeToOrderConsumedFabric = 0;

		if (data.finishedProductPreview) {
			madeToOrderConsumedFabric = data.finishedProductPreview.product.madeToOrderProfileEnabled? data.finishedProductPreview.product.madeToOrderProfile?.consumedFabric|| 0 : 0;
		}
		else if (data.fabricProductPreview) {
			madeToOrderConsumedFabric = data.fabricProductPreview.product.madeToOrderProfileEnabled? data.fabricProductPreview.product.madeToOrderProfile?.consumedFabric|| 0: 0;
		}

		if(data.customSize && Object.keys(data.customSize).length) {
			this._cartCalculation.selectedConsumedFabric = madeToOrderConsumedFabric;

			if(data.fabricProductPreview)
			{
				if(data.fabricProductPreview.product.customSizeProfileEnabled && data.fabricProductPreview.product.customSizeProfile) {
					this._cartCalculation.customSizeProfile = data.fabricProductPreview.product.customSizeProfile;
					this._cartCalculation.hasThisProductCustomSize = true;
				}
				else {
					this._cartCalculation.selectedConsumedFabric =
						data.selectedSizeOption ? data.selectedSizeOption.consumedFabric : 0;

					this._cartCalculation.customSizeProfile = {} as CustomSizeProfile;
					this._cartCalculation.hasThisProductCustomSize = false;
				}
			}
			else if (data.finishedProductPreview) {
				if(data.finishedProductPreview.product.customSizeProfileEnabled && data.finishedProductPreview.product.customSizeProfile) {
					this._cartCalculation.customSizeProfile = data.finishedProductPreview.product.customSizeProfile;
					this._cartCalculation.hasThisProductCustomSize = true;
				}
				else {
					this._cartCalculation.selectedConsumedFabric =
						data.selectedSizeOption ? data.selectedSizeOption.consumedFabric : 0;

					this._cartCalculation.customSizeProfile = {} as CustomSizeProfile;
					this._cartCalculation.hasThisProductCustomSize = false;
				}
			}
		}
		else{
			this._cartCalculation.selectedConsumedFabric =
			data.selectedSizeOption ? data.selectedSizeOption.consumedFabric : 0;

            this._cartCalculation.customSizeProfile = {} as CustomSizeProfile;
            this._cartCalculation.hasThisProductCustomSize = false;
		}


		if (data.finishedProductPreview) {
			this._cartCalculation.madeToOrderProfile = (data.finishedProductPreview.product.madeToOrderProfileEnabled && data.finishedProductPreview.product.madeToOrderProfile)
				?
				data.finishedProductPreview.product.madeToOrderProfile:{} as MadeToOrderProfile
		}
		else this._cartCalculation.madeToOrderProfile =  (data.fabricProductPreview?.product.madeToOrderProfileEnabled && data.fabricProductPreview!.product.madeToOrderProfile)
			?
			data.fabricProductPreview!.product.madeToOrderProfile:{} as MadeToOrderProfile


		this._cartCalculation.defaultProductPrice =
			data.finishedProductPreview ? data.finishedProductPreview.product.price : data.fabricProductPreview!.product.price;

		this._cartCalculation.selectedFinishForCartCalc = data.selectedFinishList.length ? data.selectedFinishList : [];

		this._cartCalculation.swatchProduct = data.productGroup === 'swatch';
		this._cartCalculation.makingCharge = data.makingCharge || 0;
		this._cartCalculation.quantity = data.quantity;

		return this._cartCalculation.cartPriceCalculation()
	}
}
