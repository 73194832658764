<!-- <p>cart works!</p> -->
<div class="cart_cont_inner">
	<button (click)="closeCart()" class="close_cart">
		<span class="material-symbols-outlined">
			close
		</span>
	</button>
	<div class="cart_item_and_discount_cont">
		<h3>
			CART
		</h3>
		<ng-container *ngIf="selectedCountry==='India'">
			<div class="cart_discount" *ngIf="price.storedAutoDiscounts&&
			price.storedAutoDiscounts.length>0 &&
			this.price.storedAutoDiscounts![0].location === 'DOMESTIC'">
				{{this.price.storedAutoDiscounts![0].discountPercentage}}%  discount on Order above
				{{preferredCurrency | uppercase}}
				{{this.price.storedAutoDiscounts![0].minimumOrderValue | currencyConverter | number: '1.0-2'}}
			</div>
		</ng-container>

		<ng-container *ngIf="selectedCountry!=='India'">
			<div class="cart_discount" *ngIf="price.storedAutoDiscounts&&
			price.storedAutoDiscounts.length>0 &&
			this.price.storedAutoDiscounts![0].location === 'INTERNATIONAL'">
				{{this.price.storedAutoDiscounts![0].discountPercentage}}%  discount on Order above
				{{preferredCurrency | uppercase}}
				{{this.price.storedAutoDiscounts![0].minimumOrderValue | currencyConverter | number: '1.0-2'}}
			</div>
		</ng-container>

		<div class="cart_item_cont">
			<ng-container *ngFor="let cart of cartDataCont;let i = index">
				<fb-cart-item [cartItem]="cart" [index]="i" (quantityUpdate)="totalPriceCalcUpdate($event)"
							  [preferredCurrency]="preferredCurrency"
					(emptyCartDisplay)="removeAllCartItem($event)">
				</fb-cart-item>
			</ng-container>
			<div class="proceed_cont">


				<ng-container *ngIf="isLoggedIn">
					<a (click)="proceedToCart()">
						<button>
							Proceed To Checkout
						</button>
					</a>
					<span class="clear_cart_button" (click)="clearCart()">Clear Cart</span>
				</ng-container>

				<ng-container *ngIf="!isLoggedIn">
					<a [routerLink]="['/auth/login']" (click)="[closeCart(), saveCurrentUrlPath()]">
						<button>
							login to Proceed
						</button>
					</a>
				</ng-container>

				<p *ngIf="!this.price.volumeDiscounterPrice && !this.price.autoDiscountPercentage" class="non_discount">Total: {{preferredCurrency | uppercase}}
					{{totalPrice | currencyConverter | number: '1.0-2'}} </p>


				<p *ngIf="this.price.volumeDiscounterPrice || this.price.autoDiscountPercentage">Total: {{preferredCurrency | uppercase}}
					{{this.price.totalPrice | currencyConverter | number: '1.0-2'}} </p>
			</div>
		</div>
	</div>
</div>
<div class="overlay_pop_up_pd" (click)="closeCart()"></div>
