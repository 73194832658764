import {Injectable} from '@angular/core';
import {Ip} from "../../../navigation/interface/ip";
import {IpResponse} from "../../interface/ip-response/ip-response";
import {HttpClient} from "@angular/common/http";
import {HttpErrorHandlerService} from "../../http-error-handler.service";
import {JWTService} from "../../../authentication/jwt.service";
import {PasteboxService} from "../../../services/pastebox.service";
import {TransmissionService} from "../transmission.service";


@Injectable({
  providedIn: 'root'
})
export class IpTransmissionService extends TransmissionService{

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}

	public getIpResponse(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: Ip) => void,
		onSuccess: (response: Ip) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executeGetPayload<IpResponse, Ip>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'currency',
			false
		);
	}
}
