import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ComponentsLibraryModule} from './components-library/components-library.module';
import {NavigationModule} from './navigation/navigation.module';
import {HomeModule} from './home/home.module';
import {HttpClientModule} from '@angular/common/http';
import {FooterModule} from './footer/footer.module';
import {BmxToastModule} from 'bmx-toast';
import {environment as env} from '../environments/environment';
import {AuthModule} from "@auth0/auth0-angular";
import {NgxGoogleTagsManagerModule} from "@bloomscorp/ngx-gtag";
import {BmxAngularBaseModule} from "@bloomscorp/ngbase";
import {RequestMapperService} from "./request-mapper.service";
import {NgxBloomsightModule} from "@bloomscorp/ngx-bloomsight";
import {MiscellaneousModule} from "./components-library/miscellaneous/miscellaneous.module";

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule.withServerTransition({appId: 'serverApp'}),
		AppRoutingModule,
		BrowserAnimationsModule,
		ComponentsLibraryModule,
		NavigationModule,
		HomeModule,
		HttpClientModule,
		FooterModule,
		BmxToastModule,
		BmxAngularBaseModule.forRoot({
			rootUrl: RequestMapperService.ROOT_URL,
			siteName: 'Anuprerna Artisan Alliance Pvt. Ltd.'
		}),
		AuthModule.forRoot({
			...env.auth
		}),
		NgxGoogleTagsManagerModule.forRoot({
			trackingId: 'G-YY5WPYVGE4',
			trackPageviews: true,
			debug: false
		}),
		NgxGoogleTagsManagerModule.forRoot({
			trackingId: 'GTM-PH84L4F',
			trackPageviews: true,
			debug: false
		}),
		NgxBloomsightModule.forRoot({
			propertyToken: '658487ab31fab49c2b08e86a',
			isDevelopmentMode: false
		}),
		MiscellaneousModule
	],
	providers: [],
	bootstrap: [AppComponent]
})
export class AppModule {
}
