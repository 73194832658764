import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'fb-cdn-video-dialog',
  templateUrl: './cdn-video-dialog.component.html',
  styleUrls: ['./cdn-video-dialog.component.scss']
})
export class CdnVideoDialogComponent {
	constructor(
		private _dialogRef: MatDialogRef<CdnVideoDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: string,
	) {
	}

	public closeVideoDialog(): void {
		this._dialogRef.close();
	}
}
