import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationComponent} from './navigation/navigation.component';
import { NewNavigationComponent } from './new-navigation/new-navigation.component';
import {RouterLink} from "@angular/router";
import {ComponentsLibraryModule} from "../components-library/components-library.module";
import {LazyLoadImageModule} from "ng-lazyload-image";
import { NavigationForexDropdownComponent } from './navigation-forex-dropdown/navigation-forex-dropdown.component';
import {CartModule} from "../cart/cart.module";
import { NavigationCustomerDropdownComponent } from './navigation-customer-dropdown/navigation-customer-dropdown.component';
import { NavigationMobile2Component } from './navigation-mobile-2/navigation-mobile-2.component';


@NgModule({
	declarations: [
		NavigationComponent,
  NewNavigationComponent,
  NavigationForexDropdownComponent,
  NavigationCustomerDropdownComponent,
  NavigationMobile2Component,
	],
    imports: [
        CommonModule,
        RouterLink,
        ComponentsLibraryModule,
        LazyLoadImageModule,
        CartModule
    ],
	exports: [
		NavigationComponent,
		NewNavigationComponent,
	]
})
export class NavigationModule {
}
