<section class="fb-home-stories-new w-full flex flex-col justify-center items-center">
	<div class="container flex flex-col lg:flex-row justify-between items-center gap-4 md:gap-8 lg:mb-9 relative">
		<div class="mb-5 lg:my-0  lg:flex-[40%] mx-2 lg:mx-0">
			<h2 class="text-3xl sm:text-5xl text-[#7D5B20] font-medium mb-4 md:mb-10">
				<a href="https://anuprerna.com/products/fabric?page=1&sort-by=availability" target="_blank">
					Handwoven Artisanal
				</a> <span class="text-black">Textiles</span> & <span class="text-black">Products</span>
			</h2>
			<p class="text-base md:text-2xl my-2">
				<span class="text-[#7D5B20]">100%</span> Natural Fibres
			</p>
			<p class="text-base md:text-2xl my-2">Fully <span class="text-[#7D5B20]">customised</span> fabrics at low MOQ</p>
			<p class="text-base md:text-2xl my-2">Seamless manufacturing <span class="text-[#7D5B20]">Apparel</span>, <span class="text-[#7D5B20]">Home</span> & <span class="text-[#7D5B20]">Accessories</span></p>

			<div class="w-full flex justify-between gap-2 items-center">
				<a [routerLink]="'/products/fabric'"
				   target="_blank"
				   class="w-full bg-[#fffcf7] hover:bg-white hover:shadow-md rounded md:rounded-lg border-2 border-[#8E7862] text-[#7D5B20] py-1 px-3 hover:border-[#6c5b48]
						transition flex items-center justify-center gap-2 text-sm sm:text-xl mt-4">
					<i class="fb_animate"><b ></b><span></span></i>
					Fabrics
				</a>
				<a [routerLink]="'/products/finished'"
				   target="_blank"
				   class="w-full bg-[#fffcf7] hover:bg-white hover:shadow-md rounded md:rounded-lg border-2 border-[#8E7862] text-[#7D5B20] py-1 px-3 hover:border-[#6c5b48]
						transition flex items-center justify-center gap-2 text-sm sm:text-xl mt-4">
					<i class="fb_animate"><b ></b><span></span></i>
					Finished Goods
				</a>
			</div>
		</div>

		<div class="hidden lg:flex flex-col justify-center items-end lg:flex-[60%] h-[400px] relative">
			<div class="fb_story_gallery ">
				<ng-container *ngFor="let blog of cards; let i = index">
					<div class="fb_story_container"
						 [ngClass]="{'fb_content_hover': blogListShow[i]}"
						 (mouseenter)="onMouseEnter(i)"
						 (mouseleave)="blogListShow[i]=false"
						 (click)="openVideoDialog(blog.src)" >
						<span class="material-symbols-outlined top">youtube_activity</span>

						<div class="fb_story_content flex justify-start gap-1.5 items-center">
							<span class="material-symbols-outlined">
								movie
							</span>
							<h3 class="fb_story_title" [innerHTML]="blog.text"></h3>
						</div>

						<img [src]="blog.poster"
							 [alt]="blog.text"/>
					</div>

				</ng-container>
			</div>
<!--			<button-->
<!--				(click)="scrollToMedia()"-->
<!--				class="absolute -bottom-1 -right-4 w-max flex justify-end gap-2 items-center shadow-md text-xs bg-gray-50 backdrop-blur-md lg:font-semibold isolation-auto border-gray-50 before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-[#B7A98F] hover:text-gray-50 before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 z-10 px-3 py-1 overflow-hidden border-2 rounded-full group"-->
<!--			>-->
<!--				View More-->
<!--				<svg-->
<!--					class="w-7 h-7 justify-end group-hover:rotate-180 group-hover:bg-gray-50 text-gray-50 ease-linear duration-300 rounded-full border border-gray-700 group-hover:border-none p-2 rotate-45"-->
<!--					viewBox="0 0 16 19"-->
<!--					xmlns="http://www.w3.org/2000/svg"-->
<!--				>-->
<!--					<path-->
<!--						d="M7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18H7ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 18L9 1H7L7 18H9Z"-->
<!--						class="fill-gray-800 group-hover:fill-gray-800"-->
<!--					></path>-->
<!--				</svg>-->
<!--			</button>-->
		</div>
		<div class="lg:hidden">
			<div class=" w-full relative">
				<div class="fb-home-hero-mobile rounded-lg m-2 overflow-hidden relative">
					<img class="w-full h-full aspect-video  object-cover relative z-[1]"
						 [src]="cards[0].poster"
						 [alt]="cards[0].text"/>
				</div>
				<div class="fb_story_content absolute bottom-4 left-1 w-full z-[2]">
					<h3 class="fb_story_title text-sm text-center text-white" [innerHTML]="cards[0].text"></h3>
				</div>
<!--				<button-->
<!--					(click)="scrollToMedia()"-->
<!--					class="absolute -bottom-4 right-1 w-max flex justify-end gap-2 items-center shadow-md text-xs bg-gray-50 backdrop-blur-md lg:font-semibold isolation-auto border-gray-50 before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-[#B7A98F] hover:text-gray-50 before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 z-10 px-3 py-1 overflow-hidden border-2 rounded-full group"-->
<!--				>-->
<!--					View More-->
<!--					<svg-->
<!--						class="w-7 h-7 justify-end group-hover:rotate-180 group-hover:bg-gray-50 text-gray-50 ease-linear duration-300 rounded-full border border-gray-700 group-hover:border-none p-2 rotate-45"-->
<!--						viewBox="0 0 16 19"-->
<!--						xmlns="http://www.w3.org/2000/svg"-->
<!--					>-->
<!--						<path-->
<!--							d="M7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18H7ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 18L9 1H7L7 18H9Z"-->
<!--							class="fill-gray-800 group-hover:fill-gray-800"-->
<!--						></path>-->
<!--					</svg>-->
<!--				</button>-->
			</div>
		</div>
	</div>
	<div class="container flex justify-end items-end">


	</div>
</section>


