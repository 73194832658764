import {Component, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {RequestMapperService} from "../../request-mapper.service";
import {JWTService} from "../../authentication/jwt.service";
import {GlobalService} from "../../services/global.service";
import {AuthService} from "@auth0/auth0-angular";
import {isPlatformBrowser} from "@angular/common";
import {AddOrderTransmissionService} from "../../raintree/transmission/order-transmission/order-transmission.service";
import {ActiveOrderIndicator} from "../interface/active-order-indicator";
import {BloomsightService} from "../../bloomsight.service";

@Component({
	selector: 'fb-navigation-customer-dropdown',
	animations: [
		trigger('enterAnimation', [
			transition(':enter', [
				style({transform: 'translateX(0)', opacity: 0}),
				animate(
					'200ms',
					style({transform: 'translateX(0)', opacity: 1})
				),
			]),
			transition(':leave', [
				style({transform: 'translateX(0)', opacity: 1}),
				animate(
					'200ms',
					style({transform: 'translateX(0)', opacity: 0})
				),
			]),
		]),
	],
	templateUrl: './navigation-customer-dropdown.component.html',
	styleUrls: ['./navigation-customer-dropdown.component.scss']
})
export class NavigationCustomerDropdownComponent implements  OnChanges {

	@Input()
	public tenantName: string = "";
	public isBrowser: boolean = false;
	public customerShow: boolean = false;
	public hasActiveOrder: boolean = false;

	constructor(
		private _jwt: JWTService,
		private _globalService: GlobalService,
		private _authService: AuthService,
		private _bloomsight: BloomsightService,
		private _orderAPI: AddOrderTransmissionService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	private _checkActiveOrderStatus(): void {
		this._orderAPI.checkActiveOrderStatus(
			() => {},
			() => {},
			(response: ActiveOrderIndicator) => {
				this.hasActiveOrder = response.hasProcessingOrder;
			},
			() => {},
			() => {}
		)
	}

	public trackNavigationClick(option: string): void {
		this.customerShow = false;
		this._bloomsight.trackDataEvent(
			'67c9865afa5f5a9c916076a9',
			[
				{option: option},
				{email: this._globalService.customerProfileData.tenant.email},
				{userID: this._globalService.customerProfileData.tenant.uid},
				{all: option + '-' + this._globalService.customerProfileData.tenant.email + '-' + this._globalService.customerProfileData.tenant.uid },
			],
			'User Dropdown Navigation Option'
		);
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes['tenantName'].previousValue !== changes['tenantName'].currentValue) {
			this._checkActiveOrderStatus();
		}
	}

	public logout(): void {
		this._jwt.destroySession();
		this.customerShow = false;

		if (this.isBrowser) {
			this._authService.logout({
				logoutParams: {
					returnTo: this.isBrowser ? window.location.origin : ''
				}
			}).subscribe(() => {
				location.href = RequestMapperService.BASE_RELATIVE_URL;
				if (this.isBrowser) {
					localStorage.removeItem("cartData");
				}

			});
		}
	}

}
