import {Injectable} from '@angular/core';
import {TransmissionService} from '../transmission.service';
import {HttpClient} from '@angular/common/http';
import {JWTService} from 'src/app/authentication/jwt.service';
import {AddOrder} from 'src/app/checkout/interface/add-order';
import {PasteboxService} from 'src/app/services/pastebox.service';
import {HttpErrorHandlerService} from '../../http-error-handler.service';
import {RaintreeResponse} from '../../interface/raintree-response';
import {OrderListResponse} from '../../interface/order-list-response/order-list-response';
import {OrderList} from 'src/app/profile/interface/order-list';
import {OrderDetailsResponse} from "../../interface/order-details-response/order-details-response";
import {OrderDetails} from "../../../profile/interface/order-details";
import {OrderwiseWorkflow} from "../../../profile/interface/orderwise-workflow";
import {ConstantService} from "../../../services/constant.service";
import {SubprocessFeedback} from "../../../profile/interface/subprocess-feedback";
import {RequestMapperService} from "../../../request-mapper.service";
import {OrderListBpm} from "../../../profile/interface/order-list-bpm";
import {ActiveOrderIndicator} from "../../../navigation/interface/active-order-indicator";

@Injectable({
	providedIn: 'root'
})
export class AddOrderTransmissionService extends TransmissionService {

	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}

	public addOrderHttp(
		url: string,
		payload: AddOrder,
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executePostPayload(
			url,
			payload,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			true
		);
	}


	public orderGetById(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: OrderDetails) => void,
		onSuccess: (response: OrderDetails) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {

		this.executeGetPayload<OrderDetailsResponse, OrderDetails>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'order',
			true
		);
	}

	public checkActiveOrderStatus(
		onPreExecute: () => void,
		onPostExecute: (response: ActiveOrderIndicator) => void,
		onSuccess: (response: ActiveOrderIndicator) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {

		this.executeGetPayload<OrderDetailsResponse, ActiveOrderIndicator>(
			RequestMapperService.GET_ACTIVE_ORDER_STATUS,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'entity',
			true
		);
	}

	public orderItemProgressGetById(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: OrderwiseWorkflow) => void,
		onSuccess: (response: OrderwiseWorkflow) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {

		this.executeGetPayload<OrderDetailsResponse, OrderwiseWorkflow>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.ORDER_WORKFLOW_ATTRIBUTE_KEY,
			true
		);
	}

	public orderItemProgressFeedback(
		id: number,
		onPreExecute: () => void,
		onPostExecute: (response: SubprocessFeedback) => void,
		onSuccess: (response: SubprocessFeedback) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {

		this.executeGetPayload<OrderDetailsResponse, SubprocessFeedback>(
			RequestMapperService.GET_ELEMENT_FEEDBACK + id,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.ORDER_WORKFLOW_FEEDBACK_ATTRIBUTE_KEY,
			true
		);
	}

	public orderListGet(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: OrderList[]) => void,
		onSuccess: (response: OrderList[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executeGetPayload<OrderListResponse, OrderList[]>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'orderList',
			true
		);
	}

	public getOrderListBpm(
		page: string = "",
		onPreExecute: () => void,
		onPostExecute: (response: OrderListBpm[]) => void,
		onSuccess: (response: OrderListBpm[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executeGetPayload<OrderListResponse, OrderListBpm[]>(
			RequestMapperService.GET_ORDER_LIST_V2 + page,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.ORDER_LIST_ATTRIBUTE_KEY,
			true
		);
	}

	public cancelOrderRequest(
		url: string,
		payload: { orderId: number, cancellationReason: string },
		onPreExecute: () => void,
		onPostExecute: (response: RaintreeResponse) => void,
		onSuccess: (response: RaintreeResponse) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	) {

		this.executeDeletePayload(
			url,
			payload,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			true
		);
	}

}
