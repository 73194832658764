import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import {RouterModule} from "@angular/router";


@NgModule({
	declarations: [
		FooterComponent
	],
    imports: [
        CommonModule,
        RouterModule,
        NgOptimizedImage,
    ],
	exports: [
		FooterComponent
	]
})
export class FooterModule {
}
