import {HttpClient} from "@angular/common/http";
import {JWTService} from "../../authentication/jwt.service";
import {HttpErrorHandlerService} from "../http-error-handler.service";
import {TransmissionService} from "./transmission.service";
import {PasteboxService} from "../../services/pastebox.service";
import {StoryPreview} from "../../story/interface/story-preview";
import {StoryListResponse} from "../interface/story-list-response";
import {Injectable} from "@angular/core";
import {StoryDetailsResponse} from "../interface/story-details-response";
import {StoryDetails} from "src/app/story-details/interface/story-details/story-details";
import {Story} from "../../story/interface/story";
import {RequestMapperService} from "../../request-mapper.service";
import {BlogDetails} from "../../blog/interface/blog-details";
import {BlogDetailsResponse} from "../interface/blog-details-response";
import {ConstantService} from "../../services/constant.service";
import {StoryDetailsV2} from "../../stories/interface/story-details";
import {ContentPreview} from "../../blog/interface/content-preview";
import {BLogListResponse} from "../interface/blog-list-response";
import {BlogPreview} from "../../navigation/interface/story-preview";
import {BlogResponse} from "../interface/blog-response/blog-response";

@Injectable({
	providedIn: 'root'
})
export class StoryTransmissionService extends TransmissionService {
	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
        _pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}

	getStoryList(
		url:string,
		onPreExecute: () => void,
		onPostExecute: (response: StoryPreview[]) => void,
		onSuccess: (response: StoryPreview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	){
		// debugger
		this.executeGetPayload<StoryListResponse,StoryPreview[]>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'storyContentList',
			false // :TODO fales
		);
	}

	getStoryListById(
		url:string,
		onPreExecute: () => void,
		onPostExecute: (response: StoryDetails) => void,
		onSuccess: (response: StoryDetails) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	){
		// debugger
		this.executeGetPayload<StoryDetailsResponse,StoryDetails>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'storyContent',
			false
		);
	}

	public getStoryListByIdCsv(
		csv: string,
		onPreExecute: () => void,
		onPostExecute: (response: StoryPreview[]) => void,
		onSuccess: (response: StoryPreview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	){
		// debugger
		this.executeGetPayload<StoryListResponse,StoryPreview[]>(
			RequestMapperService.GET_STORY_LIST_CSV + csv,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'storyContentList',
			false
		);
	}

	public getStoryDetailsById(
		id: string,
		onPreExecute: () => void,
		onPostExecute: (response: StoryDetailsV2) => void,
		onSuccess: (response: StoryDetailsV2) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {
		this.executeGetPayload<BlogDetailsResponse, StoryDetailsV2>(
			RequestMapperService.GET_STORY_CONTENT + id,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.STORY_CONTENT_ATTRIBUTE_KEY,
			false
		);
	}

	public getStoryListV2(
		onPreExecute: () => void,
		onPostExecute: (response: ContentPreview[]) => void,
		onSuccess: (response: ContentPreview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	){
		this.executeGetPayload<StoryListResponse,ContentPreview[]>(
			RequestMapperService.GET_STORY_LIST,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.STORY_CONTENT_LIST_ATTRIBUTE_KEY,
			false
		);
	}

	public getStoryListByCategories(
		ids:string,
		onPreExecute: () => void,
		onPostExecute: (response: ContentPreview[]) => void,
		onSuccess: (response: ContentPreview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	){
		this.executeGetPayload<BlogResponse,ContentPreview[]>(
			RequestMapperService.GET_STORY_LIST_BY_CATEGORIES + ids,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.STORY_CONTENT_LIST_ATTRIBUTE_KEY,
			false
		);
	}

	public getStoryRecommendedList(
		id: string,
		onPreExecute: () => void,
		onPostExecute: (response: ContentPreview[]) => void,
		onSuccess: (response: ContentPreview[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void,
	): void {
		this.executeGetPayload<BLogListResponse, ContentPreview[]>(
			RequestMapperService.GET_STORY_RECOMMENDED + id + '/recommended',
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			ConstantService.STORY_CONTENT_LIST_ATTRIBUTE_KEY,
			false
		);
	}

}
