import {Component, ElementRef, Input} from '@angular/core';
import {PrimaryNavigation} from '../interface/primary-navigation';

@Component({
	selector: 'fb-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

	@Input()
	public navShow: boolean = true;

	public isOpen: boolean = true;
	constructor(private elementRefNav: ElementRef) {

	}
	@Input() navigationData: PrimaryNavigation[] = [];


	public closeNotification(): void {
		this.isOpen = false;
	}
}
