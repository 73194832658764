
<nav class="fb-s-navigation w-full container flex justify-between items-center gap-2">

        <div class="xl:flex-[12%] flex justify-start items-center gap-2">
            <button class=" hamburger xl:hidden" (click)="isMobileMenuOpen = !isMobileMenuOpen">
                <ng-container *ngIf="isMobileMenuOpen">
				<span class="material-symbols-outlined">
					close
				</span>
                </ng-container>
                <ng-container *ngIf="!isMobileMenuOpen">
				<span class="material-symbols-outlined">
					menu
				</span>
                </ng-container>
            </button>
            <a routerLink="/"
			   (click)="isMobileMenuOpen = false"
			   class="flex justify-start items-center gap-1">
                <img class="fb-logo-svg" src="assets/img/logo_black.svg" alt="Anuperna">
                <h1 class="font-bold text-base lg:text-xl"><span class="opacity-0 hidden">A</span>nuprerna</h1>
            </a>
		</div>

        <div #dropdownBackground class="dropdownBackground">
            <span class="arrow"></span>
        </div>
        <ul class="xl:flex-[63%] text-sm xl:text-base hidden xl:flex justify-start items-center fb-default-transition">
            <li class="fb-s-nav-link"
                (mouseenter)="onEnter($event, dropdownBackground)" (mouseleave)="onLeave($event, dropdownBackground)">
                <a href="/products/fabric" class="fb-s-nav-main">Fabric</a>
                <div *ngIf="!navigationCraft.isLoading &&
			!navigationMaterial.isLoading &&
			!navigationColor.isLoading &&
			!navigationPattern.isLoading"
                     class="fb-s-nav-dropdown fb-fabric-dropdown">
                    <div class="flex justify-between items-stretch gap-1">
                        <div class="grid grid-cols-3 flex-[55%] color-tetradic-3 rounded-md pt-2">

                            <div *ngFor="let segment of navigationCraft.craft"
                                 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
                                <div class="fb-sn-category capitalize font-bold mb-2">
                                    <li><a [href]="generateUrl.generateCategoryRedirectionLink('products/fabric', segment)"
                                           class="cursor-pointer">{{segment.segmentCategoryName}}</a></li>
                                </div>
                                <div *ngFor="let subCategory of segment.optionList"
                                     class="fb-sn-sub-category">
                                    <li *ngIf="subCategory.subCategoryName !== 'Custom Product'"
                                        class="my-[3px]">
                                        <a *ngIf="subCategory.subCategoryName.toLowerCase() !== 'swatchkits'"
                                           [href]="generateUrl.generateSegmentRedirectionLink('products/fabric', segment.segmentCategoryName, subCategory.subCategoryName)"
                                           class="capitalize hover:underline cursor-pointer">
                                            {{subCategory.subCategoryName.toLowerCase()}}
                                        </a>
                                        <a *ngIf="subCategory.subCategoryName.toLowerCase() === 'swatchkits'"
                                           [href]="generateUrl.generateSegmentRedirectionLink('products/fabric', segment.segmentCategoryName, subCategory.subCategoryName)"
                                           class="bg-[#B78F9D] text-white rounded-md px-2 py-1 cursor-pointer text-base whitespace-nowrap">
                                            Order a SwatchKit
                                        </a>
                                    </li>
                                </div>
                            </div>

                        </div>

                        <div class="flex flex-wrap flex-[15%] color-tetradic-1 rounded-md">
                            <div class="w-full fb-sn-section rounded px-1.5 py-3 mx-1 my-1">
                                <div class="fb-sn-category capitalize font-bold mb-2">
                                    <li>Material</li>
                                </div>
                                <div *ngFor="let material of navigationMaterial.materials" class="fb-sn-sub-category">
                                    <li class="my-[3px]">
                                        <a [href]="generateUrl.generateRedirectionLink('products/fabric', 'material', material.materialName)"
                                           class="capitalize hover:underline cursor-pointer">
                                            {{material.materialName.toLowerCase()}}
                                        </a>
                                    </li>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap flex-[15%] color-tetradic-2 rounded-md">
                            <div class="w-full fb-sn-section rounded px-1.5 py-3 mx-1 my-1">
                                <div class="fb-sn-category capitalize font-bold mb-2">
                                    <li>Pattern</li>
                                </div>
                                <div *ngFor="let pattern of navigationPattern.patterns" class="fb-sn-sub-category">
                                    <li class="my-[3px]">
                                        <a [href]="generateUrl.generateRedirectionLink('products/fabric', 'pattern', pattern.patternName)"
                                           class="capitalize hover:underline cursor-pointer">
                                            {{pattern.patternName.toLowerCase()}}
                                        </a>
                                    </li>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-wrap flex-[15%] rounded-md">
                            <div class="w-full fb-sn-section rounded px-1.5 py-3 mx-1 my-1">
                                <div class="fb-sn-category capitalize font-bold mb-2">
                                    <li>Color</li>
                                </div>
                                <div *ngFor="let color of navigationColor.colors; let i = index" class="fb-sn-sub-category fb-sn-colors">
                                    <li class="flex justify-center items-center gap-1.5">
                                    <span class="w-7 h-7"
                                          [ngClass]="[i === 0? 'rounded-top':'', i === (navigationColor.colors.length -1)? 'rounded-bottom': '']"
                                          [ngStyle]="{'background-color': color.colorHexCode}"></span>
                                        <a [href]="generateUrl.generateRedirectionLink('products/fabric', 'color', color.colorLabel)"
                                           class="capitalize cursor-pointer">
                                            {{color.colorLabel.toLowerCase()}}
                                        </a>
                                    </li>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </li>
            <li class="fb-s-nav-link" (mouseenter)="onEnter($event,dropdownBackground)" (mouseleave)="onLeave($event,dropdownBackground)">
                <a href="/products/finished?category=accessories" class="fb-s-nav-main">Accessories</a>

                <div *ngIf="!navigationAccessories.isLoading" class="fb-s-nav-dropdown fb-finish-dropdown">
                    <div class="flex justify-between items-stretch gap-6">
                        <div class="fb-sn-segment grid grid-cols-3 flex-[55%] color-complementary rounded-md pt-2">

                            <div *ngFor="let segment of navigationAccessories.accessories"
                                 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
                                <div class="fb-sn-category capitalize font-bold mb-2">
                                    <li><a [href]="generateUrl.generateCategoryRedirectionLink('products/finished', segment)"
                                           class="cursor-pointer">{{segment.segmentCategoryName}}</a></li>
                                </div>
                                <div *ngFor="let subCategory of segment.optionList"
                                     class="fb-sn-sub-category">
                                    <li *ngIf="subCategory.subCategoryName !== 'Custom Product'"
                                        class="my-[3px]">
                                        <a [href]="generateUrl.generateSegmentRedirectionLink('products/finished', segment.segmentCategoryName, subCategory.subCategoryName)"
                                           (mouseenter)="setSelectedAccessory(subCategory)"
                                           class="capitalize hover:underline cursor-pointer">
                                            {{subCategory.subCategoryName.toLowerCase()}}
                                        </a>
                                    </li>
                                </div>
                            </div>

                        </div>

                        <img class="flex-[45%] w-full max-h-[440px] object-cover rounded-md" lazyLoad
                             [src]="selectedAccessory.subCategoryFeaturedImage"
                             [alt]="selectedAccessory.subCategoryName">
                    </div>

                </div>
            </li>
            <li class="fb-s-nav-link" (mouseenter)="onEnter($event,dropdownBackground)" (mouseleave)="onLeave($event,dropdownBackground)">
                <a href="/products/finished?category=home" class="fb-s-nav-main">Homeware</a>
                <div *ngIf="!navigationHome.isLoading" class="fb-s-nav-dropdown fb-finish-dropdown">
                    <div class="flex justify-between items-stretch gap-6">
                        <div class="fb-sn-segment grid grid-cols-3 flex-[55%] color-analogous-1 rounded-md pt-2">

                            <div *ngFor="let segment of navigationHome.home"
                                 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
                                <div class="fb-sn-category capitalize font-bold mb-2">
                                    <li><a [href]="generateUrl.generateCategoryRedirectionLink('products/finished', segment)"
                                           class="cursor-pointer">{{segment.segmentCategoryName}}</a></li>
                                </div>
                                <div *ngFor="let subCategory of segment.optionList"
                                     class="fb-sn-sub-category">
                                    <li *ngIf="subCategory.subCategoryName !== 'Custom Product'"
                                        class="my-[3px]">
                                        <a [href]="generateUrl.generateSegmentRedirectionLink('products/finished', segment.segmentCategoryName, subCategory.subCategoryName)"
                                           (mouseenter)="setSelectedHomeware(subCategory)"
                                           class="capitalize hover:underline cursor-pointer">
                                            {{subCategory.subCategoryName.toLowerCase()}}
                                        </a>
                                    </li>
                                </div>
                            </div>

                        </div>

                        <img class="flex-[45%] w-full max-h-[440px] object-cover rounded-md" lazyLoad
                             [src]="selectedHomeware.subCategoryFeaturedImage"
                             [alt]="selectedHomeware.subCategoryName">
                    </div>

                </div>
            </li>
            <li class="fb-s-nav-link" (mouseenter)="onEnter($event,dropdownBackground)" (mouseleave)="onLeave($event,dropdownBackground)">
                <a href="/products/finished?category=apparel" class="fb-s-nav-main">Apparel</a>
                <div *ngIf="!navigationApparel.isLoading" class="fb-s-nav-dropdown fb-finish-dropdown">
                    <div class="flex justify-between items-center gap-6">
                        <div class="fb-sn-segment grid grid-cols-3 flex-[55%] color-analogous-2 rounded-md pt-2">

                            <div *ngFor="let segment of navigationApparel.apparel"
                                 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
                                <div class="fb-sn-category capitalize font-bold mb-2">
                                    <li><a [href]="generateUrl.generateCategoryRedirectionLink('products/finished', segment)"
                                           class="cursor-pointer">{{segment.segmentCategoryName}}</a></li>
                                </div>
                                <div *ngFor="let subCategory of segment.optionList"
                                     class="fb-sn-sub-category">
                                    <li *ngIf="subCategory.subCategoryName !== 'Custom Product'"
                                        class="my-[3px]">
                                        <a [href]="generateUrl.generateSegmentRedirectionLink('products/finished', segment.segmentCategoryName, subCategory.subCategoryName)"
                                           (mouseenter)="setSelectedApparel(subCategory)"
                                           class="capitalize hover:underline cursor-pointer">
                                            {{subCategory.subCategoryName.toLowerCase()}}
                                        </a>
                                    </li>
                                </div>
                            </div>

                        </div>

                        <img class="flex-[45%] w-full max-h-[440px] object-cover rounded-md" lazyLoad
                             [src]="selectedApparel.subCategoryFeaturedImage"
                             [alt]="selectedApparel.subCategoryName">
                    </div>

                </div>

            </li>
            <li class="fb-s-nav-link" (mouseenter)="onEnter($event,dropdownBackground)" (mouseleave)="onLeave($event,dropdownBackground)">
                <a routerLink="/stories" class="fb-s-nav-main">
                    Collaborations
                </a>
                <div *ngIf="!navigationStoryCollaborations.isLoading &&
			!navigationStoryClusters.isLoading &&
			!navigationStoryCrafts.isLoading"
                     class="fb-s-nav-dropdown fb-story-dropdown">
                    <div class="container-d grid grid-cols-2 gap-2">
                        <div>
                            <div class="font-bold text-base">Crafts & Clusters</div>
                            <div class=" flex flex-col justify-between items-stretch gap-1">
                                <div class="grid grid-cols-2 flex-[50%] pt-2 gap-x-3">
                                    <div class="fb-sn-segment color-tetradic-3 rounded-md">
                                        <div class="font-bold text-base px-3 py-2 text-[#b37487]">Crafts</div>
                                        <div class=" grid grid-cols-2">
                                            <div *ngFor="let story of navigationStoryCrafts.crafts"
                                                 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
                                                <div class="fb-sn-category capitalize font-bold mb-2">
                                                    <li><a class="">{{story.storyCategoryName.toLowerCase()}}</a></li>
                                                </div>
                                                <div *ngFor="let subCategory of story.optionList"
                                                     class="fb-sn-sub-category">
                                                    <li *ngIf="subCategory.storyTitle !== 'Custom Product'"
                                                        class="my-[3px]">
                                                        <a href="/stories/{{subCategory.slug}}/{{subCategory.storyId}}"
                                                           (mouseenter)="setSelectedStoryCrafts(subCategory)"
                                                           class="capitalize hover:underline cursor-pointer">
                                                            {{subCategory.storyTitle.toLowerCase()}}
                                                        </a>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <img class="h-full object-cover rounded-md" lazyLoad
                                         [src]="selectedCraftsStory.bannerImage"
                                         [alt]="selectedCraftsStory.storyTitle">
                                </div>

                                <div>
                                    <div class="font-bold text-base my-1.5">Collaborations</div>
                                    <div class="flex-[50%] flex justify-start items-stretch gap-2">
                                        <div class="fb-sn-segment designers grid grid-cols-1 w-full color-tetradic-2 rounded-md pt-2">

                                            <div *ngFor="let story of navigationStoryCollaborations.collaborations"
                                                 class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
                                                <div class="fb-sn-category capitalize font-bold mb-2">
                                                    <li><a class="">{{story.storyCategoryName}}</a></li>
                                                </div>
                                                <div *ngFor="let subCategory of story.optionList"
                                                     class="fb-sn-sub-category">
                                                    <li *ngIf="subCategory.storyTitle !== 'Custom Product'"
                                                        class="my-[3px]">
                                                        <a href="/stories/{{subCategory.slug}}/{{subCategory.storyId}}"
                                                           (mouseenter)="setSelectedStoryCollaboration(subCategory)"
                                                           class="capitalize hover:underline cursor-pointer">
                                                            {{subCategory.storyTitle.toLowerCase()}}
                                                        </a>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                        <img class="designers max-w-[280px] h-[200px] object-cover rounded-md" lazyLoad
                                             [src]="selectedCollaborationStory.bannerImage"
                                             [alt]="selectedCollaborationStory.storyTitle">
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="grid grid-cols-2 pt-2 gap-x-3 mt-6">
                            <div class="fb-cluster-segment overflow-y-auto color-tetradic-1 rounded-md">
                                <div class="font-bold text-base px-3 py-2 text-[#4c6e5d]">Clusters</div>
                                <div class="grid grid-cols-2  gap-1">
                                    <div *ngFor="let story of navigationStoryClusters.clusters"
                                         class="fb-sn-section rounded px-3 py-2 mx-1 fb-default-transition">
                                        <div class="fb-sn-category capitalize font-bold mb-2">
                                            <li><a class="">{{story.storyCategoryName}}</a></li>
                                        </div>
                                        <div *ngFor="let subCategory of story.optionList"
                                             class="fb-sn-sub-category">
                                            <li *ngIf="subCategory.storyTitle !== 'Custom Product'"
                                                class="my-[3px]">
                                                <a href="/stories/{{subCategory.slug}}/{{subCategory.storyId}}"
                                                   (mouseenter)="setSelectedStoryClusters(subCategory)"
                                                   class="capitalize hover:underline cursor-pointer">
                                                    {{subCategory.storyTitle.toLowerCase()}}
                                                </a>
                                            </li>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="flex flex-col justify-stretch items-stretch gap-2">
                                <img class=" object-cover rounded-md h-[70%]" lazyLoad
                                     [src]="selectedClusterStory.bannerImage"
                                     [alt]="selectedClusterStory.storyTitle">
                                <div class="h-[30%] color-base rounded-md flex justify-center items-center">
                                    <a href="/story" class="fb-arrow-btn flex items-center justify-center fb-default-transition">
                                        <span class="mr-1">Discover More About Our Journey</span>
                                        <svg class="HoverArrow" width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
                                            <g fill-rule="evenodd">

                                                <path class="HoverArrow__linePath" d="M0 5h7"></path>
                                                <path class="HoverArrow__tipPath" d="M1 1l4 4-4 4"></path>

                                            </g>
                                        </svg>
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </li>
            <!--		<li class="fb-s-nav-link" (mouseenter)="onEnter($event,dropdownBackground)" (mouseleave)="onLeave($event,dropdownBackground)">-->
            <!--			<a routerLink="/blogs" target="_blank" class="fb-s-nav-main">Stories</a></li>-->
            <li class="fb-s-nav-link" (mouseenter)="onEnter($event,dropdownBackground)" (mouseleave)="onLeave($event,dropdownBackground)">
                <span class="fb-s-nav-main whitespace-nowrap">Our Story</span>
                <div *ngIf="!navigationCraft.isLoading" class="fb-s-nav-dropdown fb-resources-dropdown">
                    <div class="grid grid-cols-2 gap-1">
                        <div class="fb-sn-section  color-tetradic-1 rounded px-3 py-3">
                            <div class="fb-sn-category capitalize font-bold mb-2">
							<span class="flex justify-start items-center gap-2">
<!--								<span class="material-symbols-outlined">-->
                                <!--									store-->
                                <!--								</span>-->
								<li>About Us</li>
							</span>
                            </div>
                            <div class="fb-sn-sub-category">
                                <a href="/content/about-us/about-the-brand/56485" target="_blank">
                                    <li>
                                        About The Brand
                                    </li>
                                </a>
                                <a href="/content/about-us/about-our-impact/57938" target="_blank">
                                    <li>
                                        About Our Impact
                                    </li>
                                </a>
                                <a href="/content/about-us/about-the-founder/57073" target="_blank">
                                    <li>
                                        About the Founder
                                    </li>
                                </a>
                                <a href="/content/about-us/about-anuprerna-studio/53794" target="_blank">
                                    <li>
                                        About the studio
                                    </li>
                                </a>
                                <a routerLink="/contact">
                                    <li>
                                        Contact Us
                                    </li>
                                </a>
                            </div>
                        </div>


                        <div class="fb-sn-section color-tetradic-2 rounded px-3 py-3">
                            <div class="fb-sn-category  capitalize font-bold mb-2">
                                <li>Wholesale</li>
                            </div>
                            <div class="fb-sn-sub-category">
                                <a href="/content/wholesale/wholesale-production-preorder/59335" target="_blank">
                                    <li>
                                        Wholesale Production process
                                    </li>
                                </a>
                                <a href="/content/wholesale/order-fabric-swatches/59195" target="_blank">
                                    <li>
                                        Order Fabric Swatches
                                    </li>
                                </a>
                                <a href="/content/wholesale/natural-sustainable-custom-dyeing/59105" target="_blank">
                                    <li>
                                        Custom Sustainable Dyeing
                                    </li>
                                </a>
                                <a href="/content/wholesale/custom-clothing-accessories-homewares/703160" target="_blank">
                                    <li>
                                        Custom Clothing, Accessories, <br/>& Homewares
                                    </li>
                                </a>
                            </div>
                        </div>


                        <div class="fb-sn-section color-complementary-2 rounded px-3 py-3">
                            <div class="fb-sn-category capitalize font-bold mb-2">
                                <li>Care guide</li>
                            </div>
                            <div class="fb-sn-sub-category">
                                <a href="/content/care-guide/how-to-nurture-your-natural-dyed-clothing/126408" target="_blank">
                                    <li>
                                        Natural Dyed Fabric CareGuide
                                    </li>
                                </a>
                                <a href="/content/care-guide/handmade-textiles-care-guide/108968" target="_blank">
                                    <li>
                                        Handmade Textiles CareGuide
                                    </li>
                                </a>
                                <a href="/content/care-guide/say-goodbye-to-shrinkage-a-guide-for-fabric-care/2114526" target="_blank">
                                    <li>
                                        Fabric Shrinkage CareGuide
                                    </li>
                                </a>
                            </div>
                        </div>
                        <div class="fb-sn-section color-analogous-1 rounded-md flex justify-center items-center">
                            <a routerLink="/blogs"
                               target="_blank"
                               class="fb-arrow-btn flex items-center justify-center fb-default-transition">
                                <span class="mr-1">Read More Of Our Stories</span>
                                <svg class="HoverArrow" width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
                                    <g fill-rule="evenodd">

                                        <path class="HoverArrow__linePath" d="M0 5h7"></path>
                                        <path class="HoverArrow__tipPath" d="M1 1l4 4-4 4"></path>

                                    </g>
                                </svg>
                            </a>
                        </div>

                    </div>
                </div>
            </li>
            <li class="fb-s-nav-link">
                <a routerLink="/display/search" class="fb-s-nav-main flex justify-between items-center gap-1.5">
				<span class="material-symbols-outlined">
					search
				</span>
                    <span>Search</span>
                </a>
            </li>
        </ul>
        <div class="xl:flex-[25%] flex justify-end items-center gap-3">
            <fb-navigation-forex-dropdown class="hidden xl:block"></fb-navigation-forex-dropdown>

            <a routerLink="/display/search"
			   (click)="isMobileMenuOpen = false"
			   class="xl:hidden flex justify-between items-center gap-1.5">
				<span class="material-symbols-outlined">
					search
				</span>
            </a>

            <a routerLink="/wishlist"
			   (click)="isMobileMenuOpen = false"
			   class="flex justify-between items-center relative">
                <strong *ngIf="wishlistCount>0"
                        class="absolute top-[-10px] -right-2 count">
                    {{wishlistCount}}
                </strong>
                <span class="material-symbols-outlined">
				favorite
			</span>
            </a>

            <button (click)="openCart()" class="flex justify-between items-center relative mr-2 xl:mr-0">
                <strong *ngIf="cartCount>0"
                        class="absolute top-[-10px] -right-2 count">
                    {{cartCount}}
                </strong>
                <span class="material-symbols-outlined">
					shopping_cart
				</span>
            </button>

            <button *ngIf="!isLoggedIn">
                <a routerLink="/auth" (click)="storeUrl()" class="fb-arrow-btn flex items-center justify-center fb-default-transition">
                    <span class="mr-1">Sign In</span>
                    <svg class="HoverArrow" width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
                        <g fill-rule="evenodd">

                            <path class="HoverArrow__linePath" d="M0 5h7"></path>
                            <path class="HoverArrow__tipPath" d="M1 1l4 4-4 4"></path>

                        </g>
                    </svg>
                </a>
            </button>

            <button *ngIf="isLoggedIn" class="hidden xl:flex justify-between items-center relative ">
                <fb-navigation-customer-dropdown [tenantName]="tenantName"></fb-navigation-customer-dropdown>
            </button>
        </div>
</nav>

<div *ngIf="!navigationCraft.isLoading" class="xl:hidden">
    <fb-navigation-mobile-2 [isMobileMenuOpen]="isMobileMenuOpen"
                            [isLoggedIn]="isLoggedIn"
                            [tenantName]="tenantName"
                            (EisMobileMenuOpen)="isMobileMenuOpen = $event"
                            [navigationCraft]="navigationCraft"
                            [navigationMaterial]="navigationMaterial"
                            [navigationPattern]="navigationPattern"
							[navigationAccessories]="navigationAccessories"
							[navigationApparel]="navigationApparel"
							[navigationHome]="navigationHome"
							[navigationStoryClusters]="navigationStoryClusters"
							[navigationStoryCollaborations]="navigationStoryCollaborations"
							[navigationStoryCrafts]="navigationStoryCrafts"
                            [navigationColor]="navigationColor" ></fb-navigation-mobile-2>
</div>

    <div class="cart_cont" *ngIf="isCartOpen">
        <fb-cart (closeCartTrigger)="isCartOpen =false;" (updateList)="refreshCartList()"
                 (cartLength)="cartLengthUpdate($event)" [cartData]="fetchedCartData"></fb-cart>
    </div>

