import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {FinishProfileItem} from '../interface/finish-profile-item-list';
import {CartCalculationService} from 'src/app/cart/service/cart-calculation.service';
import {GlobalService} from 'src/app/services/global.service';
import {PrepareInstockCalculationService} from './prepare-instock-calculation.service';
import {CheckForDuplicateCartDataService} from 'src/app/cart/service/check-for-duplicate-cart-data.service';
import {VolumeDiscountProfile} from '../interface/volume-discount-profile';
import {FabricVolumeDiscuntCalculationService} from './fabric-volume-discunt-calculation.service';
import {VolumeDiscountProfileItem} from '../interface/volume-discount-profile-item';
import {CustomSizeProfile} from '../interface/custom-size-profile';

// import { CustomSize } from 'src/app/cart/interface/custom-size';

@Injectable({
	providedIn: 'root'
})
export class PriceCalculationService {

	constructor(
		private _cartCalculation: CartCalculationService,
		private _globalService: GlobalService,
		private _prepareItemInstockCalculation: PrepareInstockCalculationService,
		private _checkForDuplicateCartDataService: CheckForDuplicateCartDataService,
		private _fabricVolumeDiscountCalculation: FabricVolumeDiscuntCalculationService
	) { }
	public observePrice = new Subject<number>();
	public storePrice: number = 0;

	public selectedConsumedFabric:number = 0;
	public selectedCustomSize:CustomSizeProfile  = {} as CustomSizeProfile ;
	public selectedFabricPrice:number = 0;
	public finishPrice:number = 0;
	public selectedFinish: FinishProfileItem[] = [];
	public inStockFinishPrice:number = 0
	public inStockIdsParsed:string[] = []
	public defaultProductPrice:number = 0;
	public madeToOrderConsumedFabric:number = 0;
	public isThisProductFabric: boolean = true;
	public productQuantity: number = 0;
	public selectedFabricVolumeDiscountProfile: VolumeDiscountProfile = {} as VolumeDiscountProfile;
	public productVolumeDiscountProfile: VolumeDiscountProfile = {} as VolumeDiscountProfile;
	public isSelectedFabricInStock: boolean = true;
	public isSizeProfileAvailable: boolean = false;
	public isSelectedprofileAvailable: boolean = false;
	public makingCharges: number = 0;
	public isFinishMTO: boolean = true;


	public PreviousOrderType:string = '';
	public storeUndiscountedPrice: number = 0;
	public storediscountedPrice: number = 0;



	public calculatePrice(finishSelected: boolean, onLoad:boolean, profile: string): void {
		// debugger
		if( this.productVolumeDiscountProfile &&  this.productVolumeDiscountProfile.volumeDiscountProfileItemList) {
			this.productVolumeDiscountProfile.volumeDiscountProfileItemList = this.productVolumeDiscountProfile.volumeDiscountProfileItemList.sort((a: VolumeDiscountProfileItem, b: VolumeDiscountProfileItem) => a.minimumOrderQuantity - b.minimumOrderQuantity);
		}


		let calcPrice: number = 0;

		let fabricCost:number = (
			(this.selectedConsumedFabric?this.selectedConsumedFabric: this.madeToOrderConsumedFabric) *
			(this.selectedFabricPrice ? this.selectedFabricPrice : this.defaultProductPrice));


		if(finishSelected){
			this.finishPrice = this._finishPriceCalc();
			console.log('finish', this.finishPrice);
		}


		// if(this.isSelectedFabricInStock) {
		// 	this.finishPrice = this._finishPriceCalc();
		// }

		if (this.finishPrice) calcPrice = fabricCost + this.finishPrice;
		else calcPrice = fabricCost;

		if(onLoad){

			if(this.isThisProductFabric) {
				//for fabric we are calculating making charges
				this.makingCharges = this.defaultProductPrice - calcPrice;
			}
			else {
				//for finish we are addiing base price as a making charge
				this.makingCharges = this.defaultProductPrice;
			}

			console.log('onload', this.makingCharges, this.defaultProductPrice,calcPrice, profile)
		}

		console.log('onload not 1', this.makingCharges, this.defaultProductPrice, calcPrice, profile)
		calcPrice = calcPrice + this.makingCharges;

		if(this.selectedCustomSize.id) {
			calcPrice = calcPrice + this.selectedCustomSize.price
		}

		// debugger
		// console.log(this.makingCharges);
		console.log('onload not 2', this.makingCharges, this.defaultProductPrice, calcPrice)

		this.storeUndiscountedPrice = calcPrice;




		// debugger

		let discountedFabricCost:number = 0;

		if(this.selectedFabricVolumeDiscountProfile.id) { // fabric volume discount calculation

			discountedFabricCost = this._fabricVolumeDiscountCalculation.calculateFabricVolumeDiscount(

				fabricCost,
				(this.productQuantity * (this.selectedConsumedFabric?this.selectedConsumedFabric: this.madeToOrderConsumedFabric)),
				this.selectedFabricVolumeDiscountProfile
			);
		}

		if(this.isThisProductFabric) { // fabric price calculation

			if(this.productVolumeDiscountProfile.id &&
				 this.productQuantity >= this.productVolumeDiscountProfile.volumeDiscountProfileItemList[0].minimumOrderQuantity){

				let dyeingCost: number = 0;

				if(this.selectedFabricPrice){
					dyeingCost = calcPrice - fabricCost;
				}
				else {
					dyeingCost = calcPrice;
				}
				let discountedDyeingCost: number = this._fabricVolumeDiscountCalculation.calculateFabricVolumeDiscount(
					// (dyeingCost? dyeingCost :calcPrice) , //TODO: DO NOT REMOVE THIS LINE
					dyeingCost, //TODO: this is for testing
					(this.productQuantity),
					this.productVolumeDiscountProfile
				);



				if(( fabricCost > discountedFabricCost) || ( dyeingCost > discountedDyeingCost) ) {
					calcPrice = discountedDyeingCost +  discountedFabricCost;
				}

				this.storediscountedPrice = calcPrice;
			}
			else this.storediscountedPrice = 0;

		}
		else {
			let makingCost: number = calcPrice - fabricCost;
				let discountedMakingCost: number = this._fabricVolumeDiscountCalculation.calculateFabricVolumeDiscount(
					makingCost,
					(this.productQuantity),
					this.productVolumeDiscountProfile
				);

			if(this.productVolumeDiscountProfile.id && this.productQuantity >= this.productVolumeDiscountProfile.volumeDiscountProfileItemList[0].minimumOrderQuantity){



				if(( fabricCost > discountedFabricCost) || ( makingCost > discountedMakingCost) ) {
					calcPrice = discountedMakingCost +  discountedFabricCost;
				}
				this.storediscountedPrice = calcPrice;
			}
			else this.storediscountedPrice = 0;
		}



		// calcPrice = (discountedFabricCost ? discountedFabricCost : withoutDiscontedFabricCost);








		// console.log(calcPrice)
		// debugger
		this.storePrice = calcPrice;
		this._checkForDuplicateCartDataService.makingCharges = this.makingCharges;

		// this.productQuantity = 0;
		this.observePrice.next(calcPrice);

	}

	private _finishPriceCalc(): number {

		// debugger
		this._cartCalculation.selectedFinishForCartCalc = [...this.selectedFinish]; // for cart selected fabric

		let price: number[] = [];
		// console.log(this.selectedFinishPrice);
		this.selectedFinish.forEach((finish: FinishProfileItem):void => {
			price.push(finish.price)
			this._cartCalculation.selectedFinishForCartCalc.push(finish);
		});

		const arrSum:number = price.reduce((sum:number, current:number) => sum + current, 0);
		// console.log(price);
		// console.log(arrSum);
		this.isThisFinishMTO(this.selectedFinish);


		this.selectedFinish = [];
		// return arrSum - this.inStockFinishPrice

		return arrSum;

	}

	public isThisFinishMTO(data:FinishProfileItem[]): void {

		if(this.inStockIdsParsed.length === data.length) {
			this.isFinishMTO = true;
		}
		else this.isFinishMTO = false;


		for(let item of data){

			if(!this._globalService.isAnySizeAvailable) {

				this._cartCalculation.orderType = "MADE_TO_ORDER";
				this._globalService.isTheOrderInstock.next(false);
			}
			else {
				if(!item.inStock){
					this._cartCalculation.orderType = "MADE_TO_ORDER";
					this._globalService.isTheOrderInstock.next(false);
				}
				else{

					this._cartCalculation.orderType = "IN_STOCK";
					this._globalService.isTheOrderInstock.next(true);

				}
			}

		}


		if(!this.isFinishMTO) {
			this._cartCalculation.orderType = "MADE_TO_ORDER";
			this._globalService.isTheOrderInstock.next(false);

			this._prepareItemInstockCalculation.returnResultInsockQuantity$.next(true);
			return
		}


		if(!this.isSelectedFabricInStock){
				this._cartCalculation.orderType = "MADE_TO_ORDER";
				this._globalService.isTheOrderInstock.next(false);

				if(!this._prepareItemInstockCalculation.isSelectedFabricMTOF) {
					this._cartCalculation.orderType = "MADE_TO_ORDER";
					this._globalService.isTheOrderInstock.next(false);
					return
				}


				if( this._prepareItemInstockCalculation.isSelectedSizeInStock &&
					this.isSizeProfileAvailable &&
					this.inStockIdsParsed.length === data.length) {

					this._cartCalculation.orderType = "IN_STOCK";
					this._globalService.isTheOrderInstock.next(true);

					this._prepareItemInstockCalculation.returnResultInsockQuantity$.next(true);
					return
				}

				return
			}

		if( this._prepareItemInstockCalculation.isSelectedSizeInStock &&
			this.isSizeProfileAvailable &&
			this.inStockIdsParsed.length === data.length) {

			this._cartCalculation.orderType = "IN_STOCK";
			this._globalService.isTheOrderInstock.next(true);

			this._prepareItemInstockCalculation.returnResultInsockQuantity$.next(true);
			return
		}

		if(this.inStockIdsParsed.length != data.length &&  this._prepareItemInstockCalculation.isSelectedSizeInStock) {
			this._cartCalculation.orderType = "MADE_TO_ORDER";
			this._globalService.isTheOrderInstock.next(false);

			// this._prepareItemInstockCalculation.returnResultInsockQuantity$.next(true);
			return
		}

		if(!this.isSelectedFabricInStock){
			this._cartCalculation.orderType = "MADE_TO_ORDER";
			this._globalService.isTheOrderInstock.next(false);
		}


		if(this.inStockIdsParsed.length != data.length ) {
			this._cartCalculation.orderType = "MADE_TO_ORDER";
			this._globalService.isTheOrderInstock.next(false);

			// let cal = this.madeToOrderFabricQTY / (this.madeToOrderConsumedFabric || 1);
			// let cal = 5;

			// this._prepareItemInstockCalculation.insockQuantity$.next(Math.floor(cal));
			// return
		}


		if(this.inStockIdsParsed.length===0 && data.length===0 &&  this.isSelectedFabricInStock ) {
			this._cartCalculation.orderType = "IN_STOCK";
			this._globalService.isTheOrderInstock.next(true);
		}




	}




}
