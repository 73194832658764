import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {JWTService} from '../jwt.service';
import {ToastMessageService} from 'src/app/services/toast-message.service';
import {LocalStorageService} from "@bloomscorp/ngbase";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
	providedIn: 'root'
})
export class AuthGuard {

	public isBrowser: boolean = false;

	constructor(
		private _jwt: JWTService,
		private _router: Router,
		public _toast: ToastMessageService,
		private _localStorage: LocalStorageService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	private _checkAuth(state: RouterStateSnapshot): boolean {
		if (!this._jwt.hasValidJWT()) {
			if(this.isBrowser) {
				this._localStorage.store('returnUrl', state.url);
			}
			this._router.navigateByUrl('/auth')
				.then(success => {
					if (!success) this._toast.error('something is not right', 'something is not right. please Login Again')
					return;
				})
			return false;
		} else return true;
	}

	public canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this._checkAuth(state);
	}
}
