<div class="footer_cont_outer">
	<div class="footer_cont">
		<div class="footer_link_cont">
			<div class="footer_link_inner">
				<h2>
					ABOUT US
				</h2>
				<ul>
					<li>
						<a href="https://anuprerna.com/content/about-us/about-the-brand/56485" target="_blank">
							About the Brand
						</a>
					</li>
					<li>
						<a href="https://anuprerna.com/content/about-us/about-our-impact/57938" target="_blank">
							About Our Impact
						</a>
					</li>
					<li>
						<a href="https://anuprerna.com/content/about-us/about-anuprerna-studio/53794" target="_blank">
							Our Production Studio
						</a>
					</li>
					<li>
						<a href="https://anuprerna.com/content/wholesale/wholesale-production-preorder/59335" target="_blank">
							Wholesale Production
						</a>
					</li>
					<li>
						<a href="https://anuprerna.com/content/wholesale/custom-clothing-accessories-homewares/703160" target="_blank">
							Custom Clothing & More
						</a>
					</li>
				</ul>
			</div>
			<div class="footer_link_inner">
				<h2>
					Order Dashboard
				</h2>
				<ul>
					<li>
						<a href="/profile/order">Past Orders</a>
					</li>
					<li>
						<a href="https://anuprerna.com/content/wholesale/order-fabric-swatches/59195" target="_blank">Order Fabric Swatches</a>
					</li>

					<li>
						<a href="https://anuprerna.com/content/wholesale/natural-sustainable-custom-dyeing/59105" target="_blank">Natural & Organic Dyeing</a>
					</li>
					<li>
						<a routerLink="/blogs" target="_blank">Read Our Stories</a>
					</li>
				</ul>
			</div>
			<div class="footer_link_inner">
				<h2>
					Detailed Policy
				</h2>
				<ul>
					<li>
						<a href="https://anuprerna.com/content/policies/privacy-policy/173823"
						   target="_blank">Privacy Policy</a>
					</li>

					<li>
						<a href="https://anuprerna.com/content/policies/return-exchange-policy/170896"
						   target="_blank">Return & Exchange Policy</a>
					</li>
					<li>
						<a href="https://anuprerna.com/content/policies/terms-conditions/174271"
						   target="_blank">Terms & Conditions</a>
					</li>

					<li>
						<a href="https://anuprerna.com/content/policies/international-orders/174182"
						   target="_blank">International Orders</a>
					</li>

					<li>
						<a href="https://anuprerna.com/content/policies/production-policy/177227"
						   target="_blank">Production Policy</a>
					</li>

				</ul>
			</div>
		</div>
		<div class="footer_contact_info">
			<a routerLink="/contact"><h2>CONTACT INFO</h2></a>
			<div class="contact_us_info">
				<span class="material-symbols-outlined for_info_icon">
					mail
				</span>
				<p><a href="mailto: support@anuprerna.com">support&#64;anuprerna.com</a></p>
			</div>
			<div class="contact_us_info">
				<span class="for_info_image">
					<img ngSrc="https://anuprerna.com/assets/img/whatsapp.svg"
						 alt="WhatsApp"
						 height="50px"
						 width="50px" />
				</span>
				<p><a href="https://wa.me/+917483194942" target="_blank">+91 74831 94942</a></p>
			</div>
			<div class="contact_us_info">
				<p><a class="w-max rounded-md px-3 py-2 bg-[#b7a990] hover:bg-[#8d7961] text-black hover:text-white
				transition-all flex justify-center items-center gap-2"
					  routerLink="/contact">
					<span class="material-symbols-outlined">contact_support</span>
					<span>Contact us</span>
				</a></p>
			</div>
			<div class="follow_us_cont">
				<p>Follow Us:</p>
				<a href="https://twitter.com/Anuprerna6">
					<img ngSrc="https://anuprerna.com/assets/img/twitter.svg"
						 alt="Twitter"
						 height="50px"
						 width="50px" />
				</a>
				<a href="https://www.facebook.com/anuprernatelier/">
					<img ngSrc="https://anuprerna.com/assets/img/facebook.svg"
						 alt="Facebook"
						 height="50px"
						 width="50px" />
				</a>
				<a href="https://in.pinterest.com/anuprernas/">
					<img ngSrc="https://anuprerna.com/assets/img/pininterest.svg"
						 alt="Pinterest"
						 height="50px"
						 width="50px" />
				</a>
				<a href="https://www.instagram.com/anuprerna_atelier/">
					<img ngSrc="https://anuprerna.com/assets/img/instagram.svg"
						 alt="Instagram"
						 height="50px"
						 width="50px" />
				</a>
				<a href="https://www.linkedin.com/company/anuprerna/">
					<img ngSrc="https://anuprerna.com/assets/img/linkedin-anuprerna.svg"
						 alt="LinkedIn"
						 height="50px"
						 width="50px" />
				</a>
			</div>
		</div>
	</div>
	<div class="botton_footer">
		Anuprerna Artisian Pvt. Ltd, ALL RIGHTS REGISTERED
	</div>
</div>
