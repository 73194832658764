import {Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, SimpleChanges} from '@angular/core';
import {GetAndSetCartDataInLocalstorageService} from '../service/set-cart-data-in-localstorage.service';
import {Cart} from '../interface/cart';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Router} from '@angular/router';
import {JWTService} from "../../authentication/jwt.service";
import {ClearAllCartService} from "../service/clear-all-cart.service";
import {VolumeDiscountCalculationService} from 'src/app/checkout/service/volume-discount-calculation.service';
import {CheckoutPrepareCartResponseService} from 'src/app/checkout/service/checkout-prepare-cart-response.service';
import {StorePriceService} from 'src/app/checkout/service/store-price.service';
import {Price} from 'src/app/checkout/interface/price';
import {AutoDiscountCalculationService} from 'src/app/checkout/service/auto-discount-calculation.service';
import {CartCalculationService} from '../service/cart-calculation.service';
import {CheckoutFabricVolumeDiscountPriceService} from 'src/app/checkout/service/checkout-fabric-volume-discount-price.service';
import {isPlatformBrowser} from '@angular/common';
import {CurrencyResolverService} from "../../currency-resolver.service";
import {ApCurrency} from "../../pipe/enum/ap-currency";
import {BloomsightService} from "../../bloomsight.service";
import {isEmptyString} from "bmx-pastebox";
import {GlobalService} from "../../services/global.service";

@UntilDestroy()
@Component({
	selector: 'fb-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
	@Output() closeCartTrigger = new EventEmitter<boolean>();
	@Output() cartLength = new EventEmitter<number>();
	@Input() cartData: Cart[] = [];
	@Output() updateList = new EventEmitter();
	public CartData: Cart[] = [];
	public cartDataCont: Cart[] = [];
	public totalPrice: number = 0;
	public isLoggedIn: boolean = true;
	public price: Price = {} as Price;
	public selectedCountry: string = '';
	public isBrowser: boolean = false;
	public preferredCurrency: ApCurrency = ApCurrency.USD;

	constructor(
		private _route: Router,
		private _jwt: JWTService,
		private _clearAllCart: ClearAllCartService,
		private _prepareCheckoutCartResponse: CheckoutPrepareCartResponseService,
		private _volumeDiscounCalculation: VolumeDiscountCalculationService,
		private _storingPrice: StorePriceService,
		private _autodiscountCalculation: AutoDiscountCalculationService,
		private _getCartDataInLocal: GetAndSetCartDataInLocalstorageService,
		private _cartCalculation: CartCalculationService,
		private _fabricVolumeDiscountPriceService: CheckoutFabricVolumeDiscountPriceService,
		private _currencyResolver: CurrencyResolverService,
		private _bloomsight: BloomsightService,
		private _userData: GlobalService,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}

	public closeCart() {

		this.closeCartTrigger.emit(false);
		this._getCartDataInLocal.openCartModal.next(false);
	}

	public clearCart(): void {
		this._clearAllCart.deleteAllCartItem()
	}

	public proceedToCart(): void {
		this.closeCartTrigger.emit(false);
		this._getCartDataInLocal.openCartModal.next(false);
		let skus: string = this.cartData.map(item => item.sku).join();
		if (!isEmptyString(skus.replace(/,/g, '')))
			this._bloomsight.trackDataEvent(
				'65f0351efe3931befe4c46fe',
				{skus},
				''
			);

		this._bloomsight.trackDataEvent(
			'67dd7d5d5656b9d8e930c578',
			[
				{email: this._userData.customerProfileData.tenant.email},
				{userId: this._userData.customerProfileData.tenant.uid},
			],
			'Cart Popup - Proceed to Checkout'
		);
		this._route.navigate(['/checkout']);
	}

	public ngOnInit(): void {

		this.preferredCurrency = this._currencyResolver.getSelectedCurrency();
		this.selectedCountry = this._currencyResolver.getShippingCountry() || this._currencyResolver.countryOfOrigin;
		this._currencyResolver.onCountryChange.subscribe(() => {
			this.selectedCountry = this._currencyResolver.getShippingCountry() || this._currencyResolver.countryOfOrigin;
			this.preferredCurrency = this._currencyResolver.getSelectedCurrency();
		})
		this.isLoggedIn = this._jwt.hasValidJWT();
	}

	public ngOnChanges(changes: SimpleChanges) {
		// debugger

		this.totalPrice = 0; //clear the total price bcx multiple time onChanges call
		if (changes['cartData']['currentValue'].length > 0) {

			// TODO: do not remove start
			// this.cartDataCont = changes['cartData']['currentValue'];
			// this.totalPriceCalc();
			// TODO: do not remove end

			if (changes['cartData']['currentValue'][0].image) {

				this.cartDataCont = changes['cartData']['currentValue'];

				// this.cartDataCont.forEach((elm: Cart, index: number) => {
				// 	this._prepareFabricVolumeDiscountPrice(index)
				// });

				this.totalPriceCalc();
			} else {
				this.totalPriceCalc();
			}
		}
		// console.log(this.cartDataCont)


	}


	public totalPriceCalc(): void {
		this.cartDataCont.forEach((elm: Cart) => {
			let price = elm.price! * elm.quantity
			this.totalPrice += price
		});

		this.cartDataCont.sort((a: Cart, b: Cart) => a.price! - b.price!);

		// debugger

		this.cartDataCont = this._volumeDiscounCalculation.calculate(this.cartDataCont);

		this.price = this._autodiscountCalculation.intCalculate(this.cartDataCont, this.price);

		this.setCartDataInLocal(this.cartDataCont);
	}

	public totalPriceCalcUpdate(data: any): void {
		// debugger
		this.totalPrice = 0;
		let i = data.index;

		this.cartDataCont.forEach((elm: Cart) => {
			this.cartDataCont[i].quantity = data.item.quantity!
		});

		this.cartDataCont.sort()
		this.totalPriceCalc();
	}

	public setCartDataInLocal(data: Cart[]) {
		// debugger
		if (this.isBrowser) {
			localStorage.removeItem("cartData");
			localStorage.setItem("cartData", JSON.stringify(data));
		}
	}

	public removeAllCartItem(isCartClear: boolean): void {
		if (isCartClear) {

			this.cartDataCont = [];
			this.closeCartTrigger.emit(false);
		}
	}

	public saveCurrentUrlPath(): void {
		if (this.isBrowser) {
			localStorage.setItem("storeUrl", window.location.href)
		}

	}

	// private _prepareFabricVolumeDiscountPrice( index: number): void {

	// 	if(this.cartDataCont[index].selectedFabricProfileItemId) {

	// 		let priceFabricVD = this._fabricVolumeDiscountPriceService.calculateFabricVolumeDiscountPrice(this.cartDataCont[index]);

	// 		if(priceFabricVD){
	// 			this.cartDataCont[index].price = priceFabricVD;
	// 		}
	// 		else this.cartDataCont[index].price = this.cartDataCont[index].copyPrice;
	// 	}
	// }
}
