import {Injectable} from '@angular/core';
import {TransmissionService} from '../transmission.service';
import {HttpClient} from '@angular/common/http';
import {JWTService} from 'src/app/authentication/jwt.service';
import {PasteboxService} from 'src/app/services/pastebox.service';
import {HttpErrorHandlerService} from '../../http-error-handler.service';
import {ForexListResponse} from '../../interface/forex-list-response/forex-list-response';
import {ForexList} from 'src/app/navigation/interface/forex-list';

@Injectable({
	providedIn: 'root',
})
export class ForexListTransmissionService extends TransmissionService {
	constructor(
		_http: HttpClient,
		_httpErrorHandler: HttpErrorHandlerService,
		_jwt: JWTService,
		_pastebox: PasteboxService
	) {
		super(_http, _httpErrorHandler, _jwt, _pastebox);
	}

	public getForexListResponse(
		url: string,
		onPreExecute: () => void,
		onPostExecute: (response: ForexList[]) => void,
		onSuccess: (response: ForexList[]) => void,
		onFailure: (error: string) => void,
		onComplete: () => void
	) {
		this.executeGetPayload<ForexListResponse, ForexList[]>(
			url,
			onPreExecute,
			onPostExecute,
			onSuccess,
			onFailure,
			onComplete,
			'forexList',
			true
		);
	}
}
