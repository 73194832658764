import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {ApCurrency} from "../../pipe/enum/ap-currency";
import {CurrencyResolverService} from "../../currency-resolver.service";
import {StoreForexService} from "../service/store-forex.service";
import {JWTService} from "../../authentication/jwt.service";
import {UpdateForexInProfileService} from "../service/update-forex-in-profile.service";
import {Customer} from "../../raintree/interface/customer-profile/customer";
import {GlobalService} from "../../services/global.service";
import {CurrencyList} from "../model/currency-list";
import {Currency} from "../interface/currency";

@Component({
	selector: 'fb-navigation-forex-dropdown',
	animations: [
		trigger('enterAnimation', [
			transition(':enter', [
				style({transform: 'translateX(0)', opacity: 0}),
				animate(
					'200ms',
					style({transform: 'translateX(0)', opacity: 1})
				),
			]),
			transition(':leave', [
				style({transform: 'translateX(0)', opacity: 1}),
				animate(
					'200ms',
					style({transform: 'translateX(0)', opacity: 0})
				),
			]),
		]),
	],
	templateUrl: './navigation-forex-dropdown.component.html',
	styleUrls: ['./navigation-forex-dropdown.component.scss']
})
export class NavigationForexDropdownComponent implements OnInit {

	public selectedForexOption: ApCurrency = ApCurrency.UNKNOWN;
	public currencyList: Currency[] = [];

	public forexShow: boolean = false;

	constructor(
		private storeForex: StoreForexService,
		private _jwt: JWTService,
		private _updateForex: UpdateForexInProfileService,
		private _currencyResolver: CurrencyResolverService,
		private _globalService: GlobalService,
	) {
	}

	private _setCurrencyExchangeRate(): void {
		this.currencyList = CurrencyList.getExchangeRate;

		this.currencyList.forEach((currency: Currency): void => {

			if (currency.name === 'gbp') {
				currency.rate = this.storeForex.forexResponse.gbp
			} else if (currency.name === 'usd') {
				currency.rate = this.storeForex.forexResponse.usd
			} else if (currency.name === 'eur') {
				currency.rate = this.storeForex.forexResponse.eur
			}
		});
	}

	ngOnInit() {
		this._setCurrencyExchangeRate();
		this.selectedForexOption = this._currencyResolver.getSelectedCurrency();
		this._currencyResolver.selectCurrencyChange.subscribe(() => {
			this.selectedForexOption = this._currencyResolver.getSelectedCurrency();
		});
		this._globalService.customerProfile.subscribe((customerData: Customer): void => {
			this._updateForex.storeCustomerProfile = customerData;
			this._setCurrencyExchangeRate();
		});
	}

	public setForex(currency: string): void {
		this.selectedForexOption = this._currencyResolver.convertStringToApCurrency(currency);
		this._currencyResolver.onCurrencyChange(this.selectedForexOption);

		if (this._jwt.hasValidJWT()) {
			this._updateForex.updateProfileApi(this.selectedForexOption);
		}
		this.forexShow = false;
	}

	protected readonly ApCurrency = ApCurrency;
}
