import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {SizeProfileOption} from '../interface/size-profile-option';
import {Product} from '../interface/product';
import {ProductSizeProfileList} from '../interface/product-size-profile-list';
import {SizeProfileOptionItem} from '../interface/size-profile-option-item';
import {MadeToOrderFabric} from '../interface/made-to-order-fabric';
import {MadeToOrderProfile} from '../interface/made-to-order-profile';
import {FabricProfileItemList} from '../interface/fabric-profile-item-list';
import {CartCalculationService} from 'src/app/cart/service/cart-calculation.service';

@Injectable({
	providedIn: 'root',
})
export class PrepareInstockCalculationService {
	constructor(
		private _cartCalculation: CartCalculationService
	) {}

	public onLoadItemInStock = 0;
	public insockQuantity$ = new Subject<number>();
	public returnResultInsockQuantity$ = new Subject<boolean>();

	//non calculative variables
	public product: Product = {} as Product;
	public madeToOrderProfile: MadeToOrderProfile = {} as MadeToOrderProfile;
	public madeToOrderFabric: MadeToOrderFabric = {} as MadeToOrderFabric;
	public fabricProfileItemList: FabricProfileItemList[] = [];
	public sizeList: SizeProfileOption[] = [];
	public productSizeProfileList: ProductSizeProfileList[] = []; //available size list
	public madeToOrderProfileConsumedFabric: number = 0;
	public madeToOrderFabricQTY: number = 0;

	//calculative variables
	public defaultProductQTY: number = 0;
	public fabricQTY: number = 0;
	public sizeQTY: number = 0;
	public BySizeProfileConsumedFabric: number = 0;
	public avilableSizeQuantity: number = 0; //available size quantity
	public isSelectedFabricMTOF: boolean = false;
	public isSelectedSizeInStock: boolean = false;
	public isSelectedFabricInStock: boolean = true;




	public prepareItemInstockCalculation(productData: Product): void {


		this.product = productData;

		this.madeToOrderProfile = productData.madeToOrderProfileEnabled && productData.madeToOrderProfile ?productData.madeToOrderProfile:{} as MadeToOrderProfile;

		this.madeToOrderFabric = productData.madeToOrderFabric?productData.madeToOrderFabric:{} as MadeToOrderFabric;

		this.isSelectedFabricMTOF = !!productData.madeToOrderFabric;

		this.fabricProfileItemList = productData.fabricProfileEnabled && productData.fabricProfile?productData.fabricProfile.fabricProfileItemList:[];

		this.fabricQTY = this.madeToOrderFabric.totalQuantity?this.madeToOrderFabric.totalQuantity:productData.totalQuantity;

		this.madeToOrderProfileConsumedFabric = this.product.madeToOrderProfileEnabled && this.product.madeToOrderProfile?.consumedFabric
			? this.product.madeToOrderProfile?.consumedFabric
			: 0;

		this.madeToOrderFabricQTY = this.product.madeToOrderFabric?.totalQuantity
			? this.product.madeToOrderFabric?.totalQuantity
			: 0;

		this.sizeList =
			(this.product.sizeProfileEnabled && this.product.sizeProfile &&
			this.product.sizeProfile.sizeProfileOptionList.length > 0)
				? this.product.sizeProfile.sizeProfileOptionList
				: [];


		if(this.product.productSizeProfileList && this.product.productSizeProfileList.length > 0) {
			// this.product.productSizeProfileList = this.product.productSizeProfileList.filter(size => !size.disabled);
			this.productSizeProfileList = this.product.productSizeProfileList.length > 0
					? this.product.productSizeProfileList
					: [];
		}


		this.avilableSizeQuantity = this._calcAvilableSizeConsumedFabricAndQTY(
			productData,
			this.sizeList[0]
		);

		this._calcOnloadItemInStockCalculation();
	}



	private _calcOnloadItemInStockCalculation(): void {

		let calculated;

		if (this.sizeList.length<1) { // if there is no size profile list

			calculated = this.madeToOrderFabricQTY /this.madeToOrderProfileConsumedFabric;
		} else {
			if(this.isSelectedSizeInStock && this.isSelectedFabricMTOF) {

				calculated = this.sizeQTY? this.sizeQTY : this.madeToOrderFabric.totalQuantity
			}
			else {
				calculated  = this.fabricQTY / this.BySizeProfileConsumedFabric
			}
		}

		if(this.sizeList.length<1 && this.fabricProfileItemList.length<1) {
			calculated = this.product.totalQuantity;
		}

		this.onLoadItemInStock = calculated;


		let ths = this;
		setTimeout(function() {
			ths.insockQuantity$.next(Math.floor(ths.onLoadItemInStock));
		});


		// start subscription of Dynamic Insock Calculation
		this.returnResultInsockQuantity$.subscribe((isTriggerd:boolean) => {
			if(isTriggerd) this._interactiveMiscalculation()
		})
	}

	private _interactiveMiscalculation(): void {

		let cal;


		if(this.isSelectedSizeInStock && this.isSelectedFabricMTOF) {

			if(this.product.totalQuantity){ //TODO: discuss
				cal = this.product.totalQuantity;
			}
			else{
				cal = this.sizeQTY ? this.sizeQTY : this.madeToOrderFabric.totalQuantity;
			}

			if(this.product.productGroup === 'finished') {
				cal = this.sizeQTY ? this.sizeQTY : this.madeToOrderFabric.totalQuantity;
			}
		}
		else {
			if(this.isSelectedFabricMTOF) {
				if(this.product.totalQuantity){ //TODO: discuss
					cal = this.product.totalQuantity;
				}
				else{
					cal = this.fabricQTY / (this.BySizeProfileConsumedFabric || 1);
				}
			}
			else {
				if(this.isSelectedSizeInStock && !this.madeToOrderProfile.id){
					cal = this.sizeQTY ? this.sizeQTY : this.product.totalQuantity;
				}
				else {
					cal  = this.fabricQTY / (this.BySizeProfileConsumedFabric || 1);
				}
			}

		}


		if(this._cartCalculation.orderType === 'MADE_TO_ORDER' && this.isSelectedFabricMTOF) {
			cal = this.madeToOrderFabricQTY / (this.madeToOrderProfileConsumedFabric || 1);
		}

		this.insockQuantity$.next(Math.floor(cal));
	}

	private _calcAvilableSizeConsumedFabricAndQTY(
		productData: Product,
		selectedSize: SizeProfileOptionItem
	): number {

		let sizeQTY = 0;

		let selectedSizeProfile: SizeProfileOptionItem = (productData.sizeProfileEnabled && selectedSize) ||
			({} as SizeProfileOptionItem);

		if (this.productSizeProfileList.length>0) {
			//TODO: If there availabe size profile, key name - product.productSizeProfileList

			if (selectedSizeProfile.label) {
				this.productSizeProfileList.forEach(
					(avlSize: ProductSizeProfileList) => {
						if (
							selectedSizeProfile.label === avlSize.sizeProfileOption.label
						) {
								sizeQTY = avlSize.disabled ? 0: avlSize.quantity;
								this.BySizeProfileConsumedFabric = (selectedSizeProfile.consumedFabric && !avlSize.disabled) ? selectedSizeProfile.consumedFabric : 1;
								this.isSelectedSizeInStock = !avlSize.disabled;

						} else {
							this.isSelectedSizeInStock = false;
						}
					}
				);
			} else {

				//TODO: size profile is not available - use consumed fabric from MTO profile
				//TODO: need to check if default QTY is applicable in this scenario

				sizeQTY = 0;
				this.BySizeProfileConsumedFabric = this.madeToOrderProfile.consumedFabric?this.madeToOrderProfile.consumedFabric:1;
			}
		}
		else {
			//TODO: If there is no availabe size profile, key name - product.productSizeProfileList
			if(this.isSelectedFabricMTOF){
				sizeQTY = this.madeToOrderFabric.totalQuantity;
			}
			else {
				sizeQTY = this.fabricQTY;
			}

			this.BySizeProfileConsumedFabric = this.madeToOrderProfile.consumedFabric?this.madeToOrderProfile.consumedFabric: 1;
		}
		this.sizeQTY = sizeQTY;
		return sizeQTY;
	}
}
