import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RequestMapperService} from "./request-mapper.service";
import {AuthGuard} from './authentication/guard/auth.guard';
import {ProductRedirectionComponent} from "./compatibility/product-redirection/product-redirection.component";

const routes: Routes = [
	{
		path: RequestMapperService.BASE_RELATIVE_URL,
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
		pathMatch: 'full'
	},
	{
		path: RequestMapperService.NOT_FOUND_URL,
		loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
	},
	{
		path: RequestMapperService.MAINTENANCE,
		redirectTo: RequestMapperService.BASE_RELATIVE_URL,
		// loadComponent: () => import('./maintenance/maintenance/maintenance.component').then(c => c.MaintenanceComponent),
		// pathMatch: 'full'
	}
]

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		initialNavigation: 'enabledBlocking',
		scrollPositionRestoration: 'top',
		anchorScrolling: 'enabled',
		scrollOffset: [0, 80]
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
