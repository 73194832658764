<section class="fb-home-fp-new w-full flex flex-col justify-center items-center min-h-[50vh] pt-10 pb-7">
	<div class="container flex flex-col lg:flex-row justify-between items-center">
		<div class="my-5 lg:my-0  lg:flex-[30%] mx-2 lg:mx-0 px-4 lg:px-0">
			<h2 class="text-3xl sm:text-4xl">Our</h2>
			<h2 class="text-3xl sm:text-4xl text-[#7D5B20] font-medium mb-3">
				Featured Products
			</h2>
			<a [routerLink]="'/products/fabric'"
			   target="_blank"
			   class="text-lg sm:text-3xl py-2 fb_animate_icon_button">
				<i class="fb_animate"><b></b><span></span></i>
				Discover More
			</a>
		</div>
		<div class=" lg:flex-[70%]">
			<div class="fb-fp-tabs">
				<div (click)="onTabChange('fabrics', 0)"
					 [ngClass]="{'tab-selected': selectedTabIndex === 0 }">
					Fabrics
				</div>
				<div (click)="onTabChange('accessories', 1)"
					 [ngClass]="{'tab-selected': selectedTabIndex === 1 }">
					Accessories
				</div>
				<div (click)="onTabChange('home', 2)"
					 [ngClass]="{'tab-selected': selectedTabIndex === 2 }">
					Homeware
				</div>
				<div (click)="onTabChange('apparel', 3)"
					 [ngClass]="{'tab-selected': selectedTabIndex === 3 }">
					Apparel
				</div>
			</div>
		</div>
	</div>
	<div class="fb-f-product gap-5">
		<owl-carousel-o [options]="customOptions">
			<ng-container *ngFor="let featuredProduct of featuredProductsList">
				<ng-template carouselSlide>
					<a class="fb-fp-card flex flex-col justify-center items-center relative"
					   [href]="onRedirection(featuredProduct)">
						<img class="fb-fp-image"
							 lazyLoad
							 [src]="featuredProduct.subCategoryFeaturedImage"
							 [alt]="featuredProduct.subCategoryName">
						<div class="w-[90%] max-w-[300px] flex justify-between items-center fb-fp-view px-2 py-1.5 absolute bottom-5">
							<p class="text-white text-xs sm:text-sm font-semibold capitalize">
								{{ featuredProduct.subCategoryName.toLowerCase() | truncketHtml: 15 }}
							</p>
							<button class="rounded-xl text-white bg-[#6c5b48] px-2.5 py-1">View</button>
						</div>
					</a>
				</ng-template>
			</ng-container>
		</owl-carousel-o>
	</div>
</section>
