import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {RequestMapperService} from "../request-mapper.service";
import {AuthGuard} from "../authentication/guard/auth.guard";

const routes: Routes = [
	{
		path: RequestMapperService.BASE_RELATIVE_URL,
		component:HomeComponent
	},
	{
		path: RequestMapperService.STORYBOARD,
		canActivate: [AuthGuard],
		loadChildren: () => import('../storyboard/storyboard.module').then(m => m.StoryboardModule)
	},
	{
		path: RequestMapperService.STORIES_URL,
		loadComponent: () => import('../stories/story-listing/story-listing.component').then(m => m.StoryListingComponent)
	},
	{
		path: RequestMapperService.STORIES_URL + '/:slug/:storyId',
		loadComponent: () => import('../stories/story-details/story-details.component').then(m => m.StoryDetailsComponent)
	},
	// {
	// 	path: RequestMapperService.STORY_LISTING,
	// 	loadChildren: () => import('../story/story.module').then(m => m.StoryModule)
	// },
	// {
	// 	path: RequestMapperService.STORY_DETAILS,
	// 	loadChildren: () => import('../story-details/story-details.module').then(m => m.StoryDetailsModule)
	// },
	{
		path: RequestMapperService.PRODUCT,
		loadChildren: () => import('../product/product.module').then(m => m.ProductModule)
	},
	{
		path: RequestMapperService.PRODUCT_DETAILS,
		loadChildren: () => import('../product-details/product-details.module').then(m => m.ProductDetailsModule)
	},
	{
		path: RequestMapperService.CONTACT,
		loadChildren: () => import('../contact/contact.module').then(m => m.ContactModule)
	},
	{
		canActivate: [AuthGuard],
		path: RequestMapperService.CHECKOUT,
		loadChildren: () => import('../checkout/checkout.module').then(m => m.CheckoutModule)
	},
	{	canActivate: [AuthGuard],
		path: RequestMapperService.PROFILE,
		loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule)
	},
	// {
	// 	path: RequestMapperService.CUSTOM_CONTENT_URL,
	// 	loadChildren: () => import('../custom-content/custom-content.module').then(m => m.CustomContentModule)
	// },
	{
		path: RequestMapperService.CUSTOM_CONTENT_URL + '/:contentType/:slug/:blogId',
		loadComponent: () => import('../custom-content-new/custom-content-details/custom-content-details.component').then(m => m.CustomContentDetailsComponent)
	},
	{
		path: RequestMapperService.SEARCH_RESULTS_URL,
		// redirectTo: RequestMapperService.MAINTENANCE,
		loadChildren: () => import('../search/search.module').then(m => m.SearchModule)
	},
	{
		path: RequestMapperService.FILTER_URL,
		loadChildren: () => import('../filter/filter.module').then(m => m.FilterModule)
	},
	{
		path: RequestMapperService.AUTH,
		loadChildren: () => import('../authentication/authentication.module').then(m => m.AuthenticationModule)
	},
	{
		path: RequestMapperService.WISHLIST,
		loadChildren: () => import('../wishlist/wishlist.module').then(m => m.WishlistModule)
	},
	{
		path: RequestMapperService.REVIEW,
		loadChildren: () => import('../review/review.module').then(m => m.ReviewModule)
	},
	{
		path: RequestMapperService.BLOG_URL,
		loadComponent: () => import('../blog/blog-listing/blog-listing.component').then(m => m.BlogListingComponent)
	},
	{
		path: RequestMapperService.BLOG_URL + '/:slug/:blogId',
		loadComponent: () => import('../blog/blog-details/blog-details.component').then(m => m.BlogDetailsComponent)
	},
	{
		path: RequestMapperService.ARTISAN_FLOW,
		loadComponent: () => import('../fabric-flow/fabric-flow/fabric-flow.component').then(m => m.FabricFlowComponent)
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
