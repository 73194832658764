import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RequestMapperService} from "../../request-mapper.service";
import {MatDialog} from "@angular/material/dialog";
import {CdnVideoDialogComponent} from "../../components-library/video/cdn-video-dialog/cdn-video-dialog.component";

@Component({
	selector: 'fb-home-caraousel',
	templateUrl: './home-caraousel.component.html',
	styleUrls: ['./home-caraousel.component.scss']
})
export class HomeCaraouselComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('stack', { static: true }) stackElement!: ElementRef;
	private intervalId: any;
	public activeCardIndex: number = 0;
	public cdn: string = RequestMapperService.CDN_HOME + 'hero/';
	public cdnVideo: string = RequestMapperService.CDN_HOME;
	public typingEffect: boolean = false;
	public cards = [
		{ type: 'video', src: this.cdnVideo + 'weaving.mp4', poster: this.cdn + 'video-thumbnails.png', text: `Combining Technology with Traditions`},
		{ type: 'video', src: this.cdnVideo + 'dyeing.mp4', poster: this.cdn + 'home-hero-2.png', text: `Naturally Dyed, Ethically Sourced` },
		{ type: 'video', src: this.cdnVideo + 'stitching.mp4', poster: this.cdn + 'home-hero-3.png', text: `Empowering 500+ Artisans from East India` },
		{ type: 'video', src: this.cdnVideo + 'bts.mp4', poster: this.cdn + 'home-hero-4.png', text: `From Fabrics, Apparel, Homeware & More, Custom-Crafted for You` },
	];

	public blogListShow: boolean[] = [];

	ngOnInit(): void {
		this.blogListShow = new Array(this.cards.length).fill(false);
		setTimeout(() => {
			this.blogListShow[0] = true;
		}, 4000);
	}

	public onMouseEnter(i: number): void {
		this.blogListShow[i] = true;
		if (i !== 0) {
			this.blogListShow[0] = false;
		}
	}

	ngAfterViewInit(): void {
	}

	ngOnDestroy(): void {

	}


	public scrollToMedia(): void {
		const media:HTMLElement | null = document.getElementById('media-section-home');
		if (!media) return;

		media.scrollIntoView({
			behavior: 'smooth',
			block: "start",
			inline: "start"
		});

		
	}
	constructor(
		private _dialog: MatDialog
	) {
	}
	public openVideoDialog(url: string): void {
		console.log(url);
			this._dialog.open(CdnVideoDialogComponent, {
				data: url,
				maxWidth: '95vw',
				maxHeight: '90vh',
			})
	
		}

	
}
