import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {RouterLink} from "@angular/router";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: 'fb-cart-login-popup',
	standalone: true,
	imports: [CommonModule, RouterLink],
	templateUrl: './cart-login-popup.component.html',
	styleUrls: ['./cart-login-popup.component.scss']
})
export class CartLoginPopupComponent {

	public isBrowser: boolean = false;

	constructor(
		private _dialogRef: MatDialogRef<CartLoginPopupComponent>,
		@Inject(PLATFORM_ID) private _platformId: Object
	) {
		this.isBrowser = isPlatformBrowser(this._platformId);
	}
	public storeUrl(): void {
		this._dialogRef.close();
		if (this.isBrowser) {
			localStorage.setItem("storeUrl", window.location.href);
		}
	}
}
