import {Injectable} from '@angular/core';
import {ApCurrency} from "./pipe/enum/ap-currency";
import {RequestMapperService} from "./request-mapper.service";
import {Ip} from "./navigation/interface/ip";
import {IpTransmissionService} from "./raintree/transmission/ip-transmission/ip-transmission.service";
import {BrowserAPIService, LocalStorageService} from "@bloomscorp/ngbase";
import {Subject} from "rxjs";
import {UpdateForexInProfileService} from "./navigation/service/update-forex-in-profile.service";
import {JWTService} from "./authentication/jwt.service";

@Injectable({
	providedIn: 'root'
})
export class CurrencyResolverService {

	private _selectedCurrency: ApCurrency = ApCurrency.UNKNOWN;
	private _locationCurrency: ApCurrency = ApCurrency.UNKNOWN;
	private _shippingCountry: string = '';
	public countryOfOrigin: string = '';
	public selectCurrencyChange: Subject<null> = new Subject<null>();
	public onCountryChange: Subject<null> = new Subject<null>();
	private _loading: boolean = true;

	constructor(
		private _ipApi: IpTransmissionService,
		private _localStorage: LocalStorageService,
		private _profileStore: UpdateForexInProfileService,
		private _jwt: JWTService,
		private _browser: BrowserAPIService
	) {
	}

	private _setSelectedFromPreferred(currency: ApCurrency): void {
		this.setSelectedCurrency(currency);
		if(this._jwt.hasValidJWT())
			this._profileStore.updateProfileApi(currency.toLowerCase());
	}

	public getLocationCurrency(): ApCurrency {
		return this._locationCurrency;
	}

	public setLocationCurrency(currency: ApCurrency): void {
		this._locationCurrency = currency;
	}

	public getLocationCurrencyFromAPI(
		onComplete: () => void
	): void {
		if (this._browser.isServer()) {
			this.setLocationCurrency(ApCurrency.INR);
			this.countryOfOrigin = "India";
			return;
		}

		this._ipApi.getIpResponse(
			RequestMapperService.IP,
			(): void => {
				this._loading = true;
			},
			(): void => {
			},
			(response: Ip): void => {
				this.setLocationCurrency(this.convertStringToApCurrency(response.currency));
				this.countryOfOrigin = response.country;
			},
			(): void => {
				this.setLocationCurrency(ApCurrency.INR)
				this.countryOfOrigin = "India";
				//onFailure();
			},
			(): void => {
				this._loading = false;
				this.getSelectedCurrency();
				this.onCountryChange.next(null);
				onComplete();
			}
		)
	}

	public getSelectedCurrency(): ApCurrency {
		if (this._browser.isServer()) {
			return ApCurrency.INR;
		}

		if(!this._loading) {
			if (this._localStorage.retrieve('selectedCurrency')) {
				return this.convertStringToApCurrency(
					this._localStorage.retrieve('selectedCurrency')
				);
			} else {
				this.setSelectedCurrency(this.getLocationCurrency());
				return this.getSelectedCurrency();
			}
		}
		return ApCurrency.UNKNOWN;
	}

	public setSelectedCurrency(currency: ApCurrency): void {
		this._localStorage.store('selectedCurrency', currency.toLowerCase());
		this._selectedCurrency = currency;
		this.selectCurrencyChange.next(null);
	}

	public onCurrencyChange(currency: ApCurrency): void {
		this.setSelectedCurrency(currency);
	}

	public convertStringToApCurrency(currency: string): ApCurrency {
		const matchingCurrency: ApCurrency | undefined  = Object.values(ApCurrency)
			.find(value => {
				return value.toLowerCase() === currency.toLowerCase()
			});

		return matchingCurrency || ApCurrency.INR;
	}

	public getPreferredCurrency(): ApCurrency {
		const locationCurrency: ApCurrency = this.getLocationCurrency();
		const selected: ApCurrency = this.getSelectedCurrency();
		const shipping: string = this.getShippingCountry();

		if(locationCurrency === selected) {
			if(selected === ApCurrency.INR && shipping && shipping !== "India")
			{
				if(shipping === "United Kingdom") {
					this._setSelectedFromPreferred(ApCurrency.GBP);
					return ApCurrency.GBP;
				}
				else {
					this._setSelectedFromPreferred(ApCurrency.USD);
					return ApCurrency.USD;
				}
			}
			return selected;
		}
		else {
			if(selected !== ApCurrency.INR && shipping === 'India') {
				return selected;
			}
			else if(selected === ApCurrency.INR && shipping !== 'India')
			{
				this._setSelectedFromPreferred(locationCurrency);
				return locationCurrency;
			}
			else {
				return selected;
			}
		}
	}

	public setShippingCountry(country: string): void{
		this._shippingCountry = country;
		this._localStorage.store('shippingCountry', this._shippingCountry);
	}

	public getShippingCountry(): string {
		if (this._localStorage.retrieve('shippingCountry')) {
			return this._localStorage.retrieve('shippingCountry');
		} else {
			return this._shippingCountry;
		}
	}
}
